import React from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { PageWrapper } from "../../containers/Layout";
import { H2, BaseLink } from "../ui/Typography";
import {
  CONTACTS_PATH,
  DASHBOARD_PATH,
  DOCUMENTS_PATH,
  LOGIN_PATH,
  SIGNUP_PATH,
  YOUR_ACCOUNT_PATH,
} from "../../constants/routes";
import { BaseButton } from "../ui/Button";
import CanadianFlagIcon from "../ui/icons/CanadianFlagIcon";
import LanguageSelect from "./LanguageSelect";
import { selectHasCartItems } from "../../selectors/cart";
import { displayModal } from "../../actions/modal";
import { MODAL_REFER_A_FRIEND } from "../../constants/modal";
import { analyticsClickedNavLink } from "../../actions/analytics";

const LinkContainer = styled.div`
  border-bottom: ${(props) => (props.active ? "1px solid white" : "none")};
`;

const MobileNavContent = ({
  onLogout,
  currentPath,
  closeMenu,
  hasSelectedPlan,
  isFrenchAvailable,
  productSwitcherMenuProps,
  hasUserPaid,
  isAuthenticated,
}) => {
  const handleLogout = () => {
    onLogout();
    closeMenu();
  };
  const {
    translations,
    isPremium,
    assetListLink,
    hasContacts,
  } = productSwitcherMenuProps;

  const dispatch = useDispatch();
  const location = useLocation();
  const isLoginRoute = location.pathname === "/login";
  const cartHasItems = useSelector(selectHasCartItems);

  if (!isAuthenticated) {
    return (
      <Box
        position="fixed"
        top="65px"
        bottom="0"
        left="0"
        right="0"
        zIndex="100"
        bgcolor="primary.main"
      >
        <PageWrapper height="100%">
          <Box
            height="85%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            borderTop={1}
            borderColor="white"
          >
            {isLoginRoute ? (
              <Box mb={1.5} display="flex" justifyContent="center">
                <LinkContainer>
                  <BaseLink to={SIGNUP_PATH} onClick={closeMenu}>
                    <H2 color="white">
                      {translations.unauthenticatedMenuOptions.signUp}
                    </H2>
                  </BaseLink>
                </LinkContainer>
              </Box>
            ) : (
              <Box mb={1.5} display="flex" justifyContent="center">
                <LinkContainer>
                  <BaseLink to={LOGIN_PATH} onClick={closeMenu}>
                    <H2 color="white">
                      {translations.unauthenticatedMenuOptions.login}
                    </H2>
                  </BaseLink>
                </LinkContainer>
              </Box>
            )}
          </Box>
          <Box display="flex" justifyContent="center" flexDirection="row">
            {isFrenchAvailable && (
              <LanguageSelect translations={translations} />
            )}
            <Box mt={1}>
              <CanadianFlagIcon alt={translations.canadianFlagAltText} />
            </Box>
          </Box>
        </PageWrapper>
      </Box>
    );
  }

  return (
    <Box
      position="fixed"
      top="65px"
      bottom="0"
      left="0"
      right="0"
      zIndex="100"
      bgcolor="primary.main"
    >
      <PageWrapper height="100%">
        <Box
          height="85%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          borderTop={1}
          borderColor="white"
        >
          {hasSelectedPlan && (
            <>
              <Box mb={1.5} display="flex" justifyContent="center">
                <LinkContainer active={currentPath === DASHBOARD_PATH}>
                  <BaseLink to={DASHBOARD_PATH} onClick={closeMenu}>
                    <H2 color="white">{translations.yourDocuments}</H2>
                  </BaseLink>
                </LinkContainer>
              </Box>
              <Box mb={1.5} display="flex" justifyContent="center">
                <LinkContainer active={currentPath === DOCUMENTS_PATH}>
                  <BaseLink to={DOCUMENTS_PATH} onClick={closeMenu}>
                    <H2 color="white">{translations.documents}</H2>
                  </BaseLink>
                </LinkContainer>
              </Box>
            </>
          )}
          {isPremium && (
            <Box mb={1.5} display="flex" justifyContent="center">
              <LinkContainer active={currentPath === assetListLink}>
                <BaseLink to={assetListLink} onClick={closeMenu}>
                  <H2 color="white">{translations.assetList}</H2>
                </BaseLink>
              </LinkContainer>
            </Box>
          )}
          {hasContacts && (
            <Box mb={1.5} display="flex" justifyContent="center">
              <LinkContainer active={currentPath === CONTACTS_PATH}>
                <BaseLink to={CONTACTS_PATH} onClick={closeMenu}>
                  <H2 color="white">{translations.contacts}</H2>
                </BaseLink>
              </LinkContainer>
            </Box>
          )}
          <Box mb={1.5} display="flex" justifyContent="center">
            <LinkContainer active={currentPath === YOUR_ACCOUNT_PATH}>
              <BaseLink to={YOUR_ACCOUNT_PATH} onClick={closeMenu}>
                <H2 color="white">{translations.yourAccount}</H2>
              </BaseLink>
            </LinkContainer>
          </Box>
          {hasUserPaid && !cartHasItems && (
            <Box mb={1.5} display="flex" justifyContent="center">
              <LinkContainer>
                <BaseLink
                  onClick={() => {
                    dispatch(displayModal(MODAL_REFER_A_FRIEND));
                    analyticsClickedNavLink("Account", "Refer a friend");
                    closeMenu();
                  }}
                >
                  <H2 color="white">{translations.referFriend}</H2>
                </BaseLink>
              </LinkContainer>
            </Box>
          )}
          <Box display="flex" justifyContent="center">
            <BaseButton type="button" onClick={handleLogout}>
              <H2 color="white">{translations.logout}</H2>
            </BaseButton>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" flexDirection="row">
          {isFrenchAvailable && <LanguageSelect translations={translations} />}
          <Box mt={1}>
            <CanadianFlagIcon alt={translations.canadianFlagAltText} />
          </Box>
        </Box>
      </PageWrapper>
    </Box>
  );
};

export default MobileNavContent;

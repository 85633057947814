import { combineReducers } from "redux";

import { TOGGLE_CALENDLY_MODAL_ACTION } from "../actions/calendly";

const modal = (state = { open: false, url: "" }, { type, payload }) => {
  if (type === TOGGLE_CALENDLY_MODAL_ACTION) {
    return { ...payload };
  }
  return state;
};

export default combineReducers({
  modal,
});

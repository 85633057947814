import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { H4, H5, SmallBody } from "./Typography";
import CartIcon from "./icons/CartIcon";
import { ButtonLink } from "./Button";
import { productConstants } from "../plans/PlanConstants";
import { addonIcons } from "../addons/AddonIcons";
import { CHECKOUT_PATH } from "../../constants/routes";
import { PARTNER_DISCOUNT, PROMO_DISCOUNT } from "../../constants/plans";
import { useMenuToggle, useBackgroundClickClose } from "../../utilities/hooks";
import {
  menuToggleAnimationStyles,
  MENU_CLOSING_SPEED,
} from "./utils/animation-styles";
import { colorPalette } from "../../config/Theme";
import { selectAddonCount, selectIsCartWidgetOpen } from "../../selectors/cart";
import { toggleCartWidget } from "../../actions/cart";

const CartButton = styled(Box)`
  cursor: pointer;

  &:focus-visible {
    outline: ${colorPalette.white} solid 1px;
  }
`;

const CartContainerExpanded = styled.div`
  /* necessary to ensure this sits above the Plan cards and other relative positioned elements */
  z-index: 25;
  min-width: 352px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${colorPalette.willfulBlue};
  border: 2px solid ${colorPalette.willfulBlueDark2};
  border-radius: 3px;
  filter: drop-shadow(5px 5px 20px rgba(74, 74, 74, 0.2));

  ${menuToggleAnimationStyles}
`;

const CartItem = ({ item, translations }) => {
  const { isFamily, price, adultsLineItem } = item;
  let { symbol } = item;
  if (isFamily) {
    symbol = "bundle";
  }

  const productIcon = productConstants[symbol].cartIcon;

  return (
    <Box display="flex" my={1}>
      <Box mr={1}>{productIcon}</Box>
      <Box width={1}>
        <Box display="flex" justifyContent="space-between">
          <H5 align="left">{translations[symbol]}</H5>
          <SmallBody>{price}</SmallBody>
        </Box>
        {isFamily && (
          <Box mt={0.5}>
            <SmallBody>{adultsLineItem}</SmallBody>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CartAddonItem = ({ addon, translations }) => {
  const { name, price } = addon;
  const addonIcon = addonIcons.digitalExecutionAddon.cartIcon;
  return (
    <Box display="flex" my={1}>
      <Box mr={1}>{addonIcon}</Box>
      <Box width={1}>
        <Box display="flex" justifyContent="space-between">
          <H5 align="left">{name}</H5>
          <SmallBody>{price}</SmallBody>
        </Box>
        <Box mt={0.5}>
          <SmallBody>{translations.addonsLineItem}</SmallBody>
        </Box>
      </Box>
    </Box>
  );
};

const SkeletonCart = () => (
  <Box mt={3.75} bgcolor="willfulWhite.main" p={1}>
    <Skeleton variant="rect" animation="wave" width={85} height={30} />
    <Box display="flex" mt={1}>
      <Box>
        <Skeleton variant="circle" animation="wave" width={48} height={48} />
      </Box>
      <Box pl={1} width="100%">
        <Box mb={0.75}>
          <Skeleton variant="rect" animation="wave" width={105} height={16} />
        </Box>
        <Skeleton variant="rect" animation="wave" width={56} height={16} />
      </Box>
    </Box>
    <Box mt={1} mb={1}>
      <Skeleton variant="rect" animation="wave" width="100%" height={1} />
    </Box>
    <Box display="flex" justifyContent="space-between" width="100%">
      <Skeleton variant="rect" animation="wave" width={65} height={24} />
      <Skeleton variant="rect" animation="wave" width={57} height={24} />
    </Box>
  </Box>
);

const CartContents = ({
  translations,
  isLoading,
  shouldDisplayCta,
  handleClick,
  cart,
  isPaid,
}) => {
  if (
    !cart ||
    isLoading ||
    (cart.items.length === 0 && cart.addonItems.length === 0)
  ) {
    return <SkeletonCart />;
  }
  const {
    subtotal,
    items,
    hasDiscount,
    discountsData,
    promoCode,
    hasAddons,
    addonItems,
  } = cart;

  return (
    <Box mt={3.75} bgcolor="willfulWhite.main" p={1}>
      <H4 align="left">{translations.cartHeader}</H4>
      {items.map((item) => (
        <CartItem key={item.symbol} item={item} translations={translations} />
      ))}
      {hasAddons &&
        addonItems.map((addon) => (
          <CartAddonItem
            key={addon.symbol}
            addon={addon}
            translations={translations}
          />
        ))}

      <Box
        pt={1}
        border={1}
        borderColor="border.main"
        borderLeft={0}
        borderRight={0}
        borderBottom={0}
      >
        {hasDiscount &&
          discountsData.map(({ label, amount, type }) => {
            return (
              <Box display="flex" justifyContent="space-between" mb={0.5}>
                <Box display="flex">
                  {type !== PARTNER_DISCOUNT && (
                    <SmallBody bold={!promoCode}>{label}</SmallBody>
                  )}

                  {type === PARTNER_DISCOUNT && (
                    <SmallBody dangerouslySetInnerHTML={{ __html: label }} />
                  )}

                  {type === PROMO_DISCOUNT && (
                    <Box pl={0.25}>
                      <SmallBody bold>{promoCode}</SmallBody>
                    </Box>
                  )}
                </Box>
                <SmallBody whiteSpace="nowrap">{amount}</SmallBody>
              </Box>
            );
          })}

        <Box display="flex" justifyContent="space-between">
          <H5>{translations.subtotal}</H5>
          <H5>{subtotal}</H5>
        </Box>
      </Box>
      {shouldDisplayCta && (
        <Box mt={2}>
          <ButtonLink
            to={CHECKOUT_PATH}
            onClick={handleClick}
            text={translations.button.checkout}
            fullWidth
            displayArrowRight
          />
        </Box>
      )}
    </Box>
  );
};

const MobileCart = ({
  itemCount,
  isPaid,
  shouldDisplayCta,
  analyticsOpenedCartWidget,
}) => {
  // only display cart icon if not on review/checkout (UX debt to address later)
  const addonCount = useSelector(selectAddonCount);
  if (!shouldDisplayCta) return null;
  return (
    <Link
      to={CHECKOUT_PATH}
      onClick={() => analyticsOpenedCartWidget()}
      style={{ display: "flex" }}
    >
      <CartIcon isMobile cartItemCount={itemCount || addonCount} />
    </Link>
  );
};

const CartWidget = ({
  itemCount,
  hasCartData,
  fetchCart,
  cart,
  isLoading,
  isPaid,
  currentPath,
  translations,
  analyticsOpenedCartWidget,
}) => {
  const { handleToggle, isOpen, isClosing } = useMenuToggle(MENU_CLOSING_SPEED);

  const menuRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const addonCount = useSelector(selectAddonCount);
  const isOpenRedux = useSelector(selectIsCartWidgetOpen);
  const dispatch = useDispatch();
  const isOpenLocalOrRedux = isOpen || isOpenRedux;

  const handleMenuToggle = (toggle) => {
    if (!hasCartData) {
      fetchCart();
    }
    if (toggle) {
      // if opening, send event to Amplitude
      analyticsOpenedCartWidget();
    }
    handleToggle(toggle);
  };
  const handleCloseHTML = useCallback(() => {
    dispatch(toggleCartWidget(false));
    handleToggle(false);
  }, [dispatch, handleToggle]);

  useBackgroundClickClose(menuRef, isOpenLocalOrRedux, handleCloseHTML);

  const shouldDisplayCta = currentPath !== CHECKOUT_PATH;

  if (itemCount === 0 && addonCount === 0) return null;

  if (isMobile)
    return (
      <Box display="flex" alignItems="center" mr={1}>
        <MobileCart
          itemCount={itemCount || addonCount}
          isPaid={isPaid}
          shouldDisplayCta={shouldDisplayCta}
          analyticsOpenedCartWidget={analyticsOpenedCartWidget}
        />
      </Box>
    );

  return (
    <Box ref={menuRef} display="flex" position="relative" alignItems="center">
      {isOpenLocalOrRedux && (
        <CartContainerExpanded isOpen={isOpen} isClosing={isClosing}>
          <CartContents
            cart={cart}
            shouldDisplayCta={shouldDisplayCta}
            isPaid={isPaid}
            isLoading={isLoading}
            handleClick={() => handleMenuToggle(false)}
            translations={translations}
          />
        </CartContainerExpanded>
      )}
      <CartButton
        component="button"
        aria-expanded={isOpen}
        aria-label={translations.button.cartButtonAriaLabel}
        display="flex"
        mr={1}
        border="none"
        borderRadius="0"
        bgcolor="transparent.main"
        onClick={() => handleMenuToggle(!isOpen)}
        zIndex={6}
      >
        <CartIcon isMobile={isMobile} cartItemCount={itemCount || addonCount} />
      </CartButton>
    </Box>
  );
};

export default CartWidget;

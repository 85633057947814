import R from "ramda";
import { change, getFormValues, startSubmit, stopSubmit } from "redux-form";
import { takeEvery, put, select, call } from "redux-saga/effects";
import { UPDATE_STATUS_TYPE } from "../actions/status";
import { deepTransformKeysToCamelCase } from "../utilities/helpers";
import {
  CHILDREN_GUARDIANS_FORM_ID,
  CONTACT_FIELD_ID,
  CONTACT_MODAL_FORM_ID,
  EXECUTORS_FORM_ID,
  NOTIFICATIONS_FORM_ID,
  PET_GUARDIANS_FORM_ID,
} from "../constants/forms";
import { closeToast, displayToast } from "../actions/toast";
import {
  MODAL_DELETE_CONTACT,
  MODAL_EDIT_CONTACT,
  MODAL_UNABLE_TO_DELETE_CONTACT,
} from "../constants/modal";
import { closeModal, displayModal } from "../actions/modal";
import {
  contactUpdatedSuccessfully,
  storeContactableData,
  NOTIFY_CONTACT_TYPE,
  OPEN_MODAL_CONTACT_TYPE,
  REMOVE_SECONDARY_CONTACT_TYPE,
  SELECT_CONTACT_FROM_DROPDOWN_TYPE,
} from "../actions/contacts";
import { fetchDashboard } from "../actions/dashboard";
import {
  UPDATE_PERSON_BACKUP_SUCCESS,
  ADD_CHILDREN_GUARDIAN_SUCCESS,
  ADD_CONTACT_SUCCESS,
  ADD_EXECUTOR_SUCCESS,
  ADD_PERSONAL_ATTORNEY_SUCCESS,
  ADD_PET_GUARDIAN_SUCCESS,
  ADD_PROPERTY_ATTORNEY_SUCCESS,
  EDIT_CONTACT_SUCCESS,
  REMOVE_CONTACT_SUCCESS,
  SEND_NOTIFY_CONTACT_SUCCESS,
} from "../constants/toasts";
import { submitFormRequest } from "./forms";
import {
  submitContactNotification,
  submitContactEndpoint,
  removeContactEndpoint,
} from "../api/contacts";
import {
  selectContactById,
  selectLastContactableData,
  selectLastSelectedContactData,
} from "../selectors/contacts";
import {
  CHILDREN_GUARDIAN_CONTACTABLE_MODEL,
  GIFT_CONTACTABLE_MODEL,
  EXECUTOR_CONTACTABLE_MODEL,
  PET_GUARDIAN_CONTACTABLE_MODEL,
  PROPERTY_ATTORNEY_CONTACTABLE_MODEL,
  PERSONAL_ATTORNEY_CONTACTABLE_MODEL,
  BACKUP_CONTACTABLE_MODEL,
} from "../constants/contacts";
import { updateChildrenGuardians } from "./children-guardians";
import { getFormData } from "../actions/forms";
import { childrenGuardianUpdatedSuccesfully } from "../actions/children-guardians";
import { updatePetGuardians } from "./pet-guardians";
import { petGuardianUpdatedSuccesfully } from "../actions/pet-guardians";
import { updateGiftsOnProgress } from "./gifts";
import { updateExecutors } from "./executors";
import { executorAddedSuccessfully } from "../actions/executors";
import { updatePropertyAttorneys } from "./property-attorneys";
import {
  fetchPropertyAttorneys,
  propertyAttorneyUpdatedSuccessfully,
} from "../actions/property-attorneys";
import { updatePersonalAttorneys } from "./personal-attorneys";
import {
  fetchPersonalAttorneys,
  PersonalAttorneyUpdatedSuccessfully,
} from "../actions/personal-attorneys";
import { handleUpdatePersonBackupOnProgress } from "./allocations";
import {
  personBackupUpdatedSuccesfully,
  fetchBackup,
} from "../actions/allocations";
import { analyticsNotifyContact } from "../actions/analytics";

function* buildContactAttributes(contact, contactableId, isSecondaryContact) {
  const { firstName, middleName, lastName } = contact;
  let contactAttributes = {};
  if (isSecondaryContact) {
    contactAttributes = {
      spouseFirstName: firstName,
      spouseMiddleName: middleName,
      spouseLastName: lastName,
    };
  } else {
    contactAttributes = {
      firstName,
      middleName,
      lastName,
    };
  }
  return yield {
    id: contactableId,
    ...contactAttributes,
  };
}

function* updateStatusInContacts({ payload }) {
  const {
    user: { contacts },
  } = payload;
  if (contacts.length === 0) {
    return yield put({ type: CONTACTS_FORMATTED_TYPE, contacts });
  }
  const formattedContactPayload = deepTransformKeysToCamelCase(contacts);
  return yield put({
    type: CONTACTS_FORMATTED_TYPE,
    payload: formattedContactPayload,
  });
}

function* updateContact(contactToUpdate) {
  const { status, contact } = yield call(submitFormRequest, {
    apiCall: submitContactEndpoint,
    formId: CONTACT_MODAL_FORM_ID,
    values: {
      ...R.mapObjIndexed(R.defaultTo(null), contactToUpdate),
      firstName: R.propOr(null, "firstName")(contactToUpdate),
      middleName: R.propOr(null, "middleName")(contactToUpdate),
      lastName: R.propOr(null, "lastName")(contactToUpdate),
      email: R.propOr(null, "email")(contactToUpdate),
      relationship: R.propOr(null, "relationship")(contactToUpdate),
    },
  });
  if (status !== 200 || !contact) {
    yield put(stopSubmit(CONTACT_MODAL_FORM_ID));
    return null;
  }
  yield put(fetchDashboard());
  // if contact is updated successfully, return the updated contact
  // to forms that use bulkupdate
  const lastContactableData = yield select(selectLastContactableData);
  if (!lastContactableData) return contact;

  const formId = R.propOr(null, "formId")(lastContactableData);
  const fieldId = R.propOr(null, "fieldId")(lastContactableData);
  const fieldIndex = R.propOr(null, "fieldIndex")(lastContactableData);
  if (fieldIndex !== null && formId && fieldId) {
    const formData = yield select(getFormValues(formId));
    const newFieldsList = formData[fieldId].map((obj, index) => {
      if (index === fieldIndex) {
        return {
          ...obj,
          contact: {
            id: contact.id,
          },
          contactId: contact.id,
        };
      }
      return obj;
    });
    yield put(change(formId, fieldId, newFieldsList));
  }
  return contact;
}

function* handleOpenModalContact({ payload }) {
  yield put(closeToast());
  const contact = R.propOr(null, "contact")(payload);
  const isDestroy = R.propOr(false, "isDestroy")(contact);
  const modalKey = R.propOr(null, "modalKey")(payload);
  const contactableId = R.propOr(null, "contactableId")(payload);
  const isSecondaryContact = R.propOr(null, "isSecondaryContact")(payload);
  const formId = R.propOr(null, "formId")(payload);
  const fieldId = R.propOr(null, "fieldId")(payload);
  const fieldIndex = R.propOr(null, "fieldIndex")(payload);
  // Editing a contact
  if (contact) {
    if (isDestroy) {
      // if destroy flag is present, open delete modal
      const deleteModalKey = contact.hasRoles
        ? MODAL_UNABLE_TO_DELETE_CONTACT
        : MODAL_DELETE_CONTACT;
      return yield put(displayModal(deleteModalKey));
    }
    yield put(change(CONTACT_MODAL_FORM_ID, CONTACT_FIELD_ID, contact));
    return yield put(displayModal(MODAL_EDIT_CONTACT));
  }
  // Adding a contact directly or for a specific contactable. The formId, fieldId and fieldIndex
  // are used to update the form field with the new contact id, only for forms that use bulkupdate
  yield put(
    storeContactableData({
      contactableId,
      isSecondaryContact,
      formId,
      fieldId,
      fieldIndex,
    }),
  );
  return yield put(displayModal(modalKey));
}

export function* handleAddOrEditContact(
  isEditing = false,
  contactableModel = null,
) {
  yield put(startSubmit(CONTACT_MODAL_FORM_ID));
  try {
    const formData = yield select(getFormValues(CONTACT_MODAL_FORM_ID));
    const { contact } = formData;
    let responseContact = null;
    if (contact) {
      responseContact = yield call(updateContact, contact);
      yield put(contactUpdatedSuccessfully(contact));
    }
    yield put(closeModal());

    if (responseContact) {
      const lastContactableData = yield select(selectLastContactableData);
      const isSecondaryContact = R.propOr(
        false,
        "isSecondaryContact",
      )(lastContactableData);
      const contactableId = R.propOr(
        null,
        "contactableId",
      )(lastContactableData);
      const fieldIndex = R.propOr(null, "fieldIndex")(lastContactableData);
      const formattedResponseContact = deepTransformKeysToCamelCase(
        responseContact,
      );
      const contactable = yield call(
        buildContactAttributes,
        formattedResponseContact,
        contactableId,
        isSecondaryContact,
      );
      const contactId = responseContact.id;

      switch (contactableModel) {
        case CHILDREN_GUARDIAN_CONTACTABLE_MODEL: {
          yield call(
            updateChildrenGuardians,
            contactable,
            contactId,
            isSecondaryContact,
          );
          yield put(getFormData(CHILDREN_GUARDIANS_FORM_ID));
          break;
        }
        case PET_GUARDIAN_CONTACTABLE_MODEL: {
          yield call(
            updatePetGuardians,
            contactable,
            contactId,
            isSecondaryContact,
          );
          yield put(getFormData(PET_GUARDIANS_FORM_ID));
          break;
        }
        case GIFT_CONTACTABLE_MODEL: {
          yield call(updateGiftsOnProgress, contactable, contactId, fieldIndex);
          break;
        }
        case BACKUP_CONTACTABLE_MODEL: {
          yield call(
            handleUpdatePersonBackupOnProgress,
            contactable,
            contactId,
          );
          yield put(fetchBackup());
          break;
        }
        case EXECUTOR_CONTACTABLE_MODEL: {
          yield call(
            updateExecutors,
            contactable,
            contactId,
            isSecondaryContact,
          );
          yield put(getFormData(EXECUTORS_FORM_ID));
          break;
        }
        case PROPERTY_ATTORNEY_CONTACTABLE_MODEL: {
          yield call(updatePropertyAttorneys, contactable, contactId);
          yield put(fetchPropertyAttorneys());
          break;
        }
        case PERSONAL_ATTORNEY_CONTACTABLE_MODEL: {
          yield call(updatePersonalAttorneys, contactable, contactId);
          yield put(fetchPersonalAttorneys());
          break;
        }
        default: {
          break;
        }
      }
    }
    yield put(
      displayToast(isEditing ? EDIT_CONTACT_SUCCESS : ADD_CONTACT_SUCCESS),
    );
  } catch (error) {
    yield put(stopSubmit(CONTACT_MODAL_FORM_ID, error.formErrors));
  }
}

export function* handleRemoveContact() {
  const contactToDelete = yield select(selectLastSelectedContactData);
  const { status, contact } = yield call(submitFormRequest, {
    apiCall: removeContactEndpoint,
    formId: CONTACT_MODAL_FORM_ID,
    values: {
      ...contactToDelete,
    },
  });
  yield put(closeModal());
  yield put(closeToast());
  if (status !== 200 || !contact) {
    return yield put(stopSubmit(CONTACT_MODAL_FORM_ID));
  }
  return yield put(displayToast(REMOVE_CONTACT_SUCCESS));
}

function* handleNotifyContact(action) {
  const {
    email,
    id,
    firstName,
    lastName,
    roles,
    secondaryRoles,
  } = action.payload;

  const { status } = yield call(submitFormRequest, {
    apiCall: submitContactNotification,
    formId: NOTIFICATIONS_FORM_ID,
    values: {
      email,
      contactId: id,
      firstName,
      lastName,
      roles,
      secondaryRoles,
    },
  });
  if (status !== 200) {
    return yield put(stopSubmit(NOTIFICATIONS_FORM_ID));
  }
  const mergedRoles = secondaryRoles ? [...roles, ...secondaryRoles] : roles;
  yield put(analyticsNotifyContact({ roles: mergedRoles }));
  return yield put(displayToast(SEND_NOTIFY_CONTACT_SUCCESS));
}

function* handleSelectContactFromDropdown({ payload }) {
  const {
    contactId,
    contactableId,
    contactableModel,
    isSecondaryContact = false,
  } = payload;
  if (contactId && contactableModel) {
    const { contact } = yield select(selectContactById(contactId));
    const newContactable = yield call(
      buildContactAttributes,
      contact,
      contactableId,
      isSecondaryContact,
    );

    if (contact) {
      switch (contactableModel) {
        case CHILDREN_GUARDIAN_CONTACTABLE_MODEL: {
          yield call(
            updateChildrenGuardians,
            newContactable,
            contactId,
            isSecondaryContact,
          );
          yield put(getFormData(CHILDREN_GUARDIANS_FORM_ID));
          if (!isSecondaryContact) {
            yield put(childrenGuardianUpdatedSuccesfully(newContactable));
            yield put(displayToast(ADD_CHILDREN_GUARDIAN_SUCCESS));
          }
          break;
        }
        case PET_GUARDIAN_CONTACTABLE_MODEL: {
          yield call(
            updatePetGuardians,
            newContactable,
            contactId,
            isSecondaryContact,
          );
          yield put(getFormData(PET_GUARDIANS_FORM_ID));
          if (!isSecondaryContact) {
            yield put(petGuardianUpdatedSuccesfully(newContactable));
            yield put(displayToast(ADD_PET_GUARDIAN_SUCCESS));
          }
          break;
        }
        case BACKUP_CONTACTABLE_MODEL: {
          yield call(
            handleUpdatePersonBackupOnProgress,
            newContactable,
            contactId,
          );
          yield put(personBackupUpdatedSuccesfully(newContactable));
          yield put(fetchBackup());
          yield put(displayToast(UPDATE_PERSON_BACKUP_SUCCESS));
          break;
        }
        case EXECUTOR_CONTACTABLE_MODEL: {
          yield call(
            updateExecutors,
            newContactable,
            contactId,
            isSecondaryContact,
          );
          yield put(getFormData(EXECUTORS_FORM_ID));
          if (!isSecondaryContact) {
            yield put(executorAddedSuccessfully(newContactable));
            yield put(displayToast(ADD_EXECUTOR_SUCCESS));
          }
          break;
        }
        case PROPERTY_ATTORNEY_CONTACTABLE_MODEL: {
          yield call(updatePropertyAttorneys, newContactable, contactId);
          yield put(propertyAttorneyUpdatedSuccessfully(newContactable));
          yield put(fetchPropertyAttorneys());
          yield put(displayToast(ADD_PROPERTY_ATTORNEY_SUCCESS));
          break;
        }
        case PERSONAL_ATTORNEY_CONTACTABLE_MODEL: {
          yield call(updatePersonalAttorneys, newContactable, contactId);
          yield put(PersonalAttorneyUpdatedSuccessfully(newContactable));
          yield put(fetchPersonalAttorneys());
          yield put(displayToast(ADD_PERSONAL_ATTORNEY_SUCCESS));
          break;
        }
        default: {
          break;
        }
      }
    }
    return null;
  }
  return null;
}

function* handleRemoveSecondaryContact({ payload }) {
  const { contactId, contactableId, contactableModel } = payload;
  if (contactId && contactableModel) {
    const eraseSecondaryContact = {
      id: contactableId,
      spouseFirstName: null,
      spouseMiddleName: null,
      spouseLastName: null,
    };
    const isSecondaryContact = true;
    const removeSecondaryContact = true;

    switch (contactableModel) {
      case CHILDREN_GUARDIAN_CONTACTABLE_MODEL: {
        yield call(
          updateChildrenGuardians,
          eraseSecondaryContact,
          contactId,
          isSecondaryContact,
          removeSecondaryContact,
        );
        yield put(getFormData(CHILDREN_GUARDIANS_FORM_ID));
        break;
      }
      case PET_GUARDIAN_CONTACTABLE_MODEL: {
        yield call(
          updatePetGuardians,
          eraseSecondaryContact,
          contactId,
          isSecondaryContact,
          removeSecondaryContact,
        );
        yield put(getFormData(PET_GUARDIANS_FORM_ID));
        break;
      }
      case EXECUTOR_CONTACTABLE_MODEL: {
        yield call(
          updateExecutors,
          eraseSecondaryContact,
          contactId,
          isSecondaryContact,
          removeSecondaryContact,
        );
        yield put(getFormData(EXECUTORS_FORM_ID));
        break;
      }
      default: {
        break;
      }
    }
    return null;
  }
  return null;
}

export function* watchUpdateStatusInContacts() {
  yield takeEvery(UPDATE_STATUS_TYPE, updateStatusInContacts);
}

export function* watchOpenModalContact() {
  yield takeEvery(OPEN_MODAL_CONTACT_TYPE, handleOpenModalContact);
}

export function* watchNotifyContact() {
  yield takeEvery(NOTIFY_CONTACT_TYPE, handleNotifyContact);
}

export function* watchSelectContactFromDropdown() {
  yield takeEvery(
    SELECT_CONTACT_FROM_DROPDOWN_TYPE,
    handleSelectContactFromDropdown,
  );
}

export function* watchRemoveSecondaryContact() {
  yield takeEvery(REMOVE_SECONDARY_CONTACT_TYPE, handleRemoveSecondaryContact);
}

export const CONTACTS_FORMATTED_TYPE = "CONTACTS_FORMATTED";

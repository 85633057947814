import React, { useState, useEffect, useCallback } from "react";

import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import { H4, H5, MiceType, MiceTypeLink, SmallBody } from "./Typography";
import PresentIcon from "./icons/PresentIcon";
import {
  REFERRAL_PROGRAM_AMAZON_GIFTCARD_URL,
  REFERRAL_PROGRAM_WILLFUL_ZENDESK_URL,
} from "../../constants/routes";
import {
  analyticsCopiedReferralLink,
  analyticsManuallyCopiedReferralLink,
  analyticsClickExternalLink,
} from "../../actions/analytics";
import {
  COPY_LINK_BUTTON_CLASS,
  REFERRAL_LINK_CLASS,
} from "../../constants/growsurf";

const GrowSurfEmbeddedForm = ({ translations, email }) => {
  return (
    <div
      data-grsf-block-form
      data-grsf-email={email}
      data-grsf-copy-link-button-text={translations.copyButton}
      data-grsf-button-style="{'background-color': '#FFFFFF', 'border': '1px solid #CACDD4', 'border-radius': '2px', 'color': '#0064DC', 'font-family': 'TT Norms', 'font-size': '16px'}"
      data-grsf-email-button-style="{'min-width': '115px','padding-left': '20px' , 'background-color': '#4A4A4A', 'color': '#FFFFFF', 'font-family': 'TT Norms', 'font-size': '18px'}"
      data-grsf-facebook-button-style="{'min-width': '115px','padding-left': '20px' , 'background-color': '#3B5998', 'color': '#FFFFFF', 'font-family': 'TT Norms', 'font-size': '18px'}"
      data-grsf-twitter-button-style="{'min-width': '115px','padding-left': '20px','margin-right': '5px' , 'background-color': '#1DA1F2;', 'color': '#FFFFFF', 'font-family': 'TT Norms', 'font-size': '18px'}"
      data-grsf-share-instructions="" // required else will create empty paragraph + headers with margin
      data-grsf-share-instructions-style="{'margin': '8px 0 0 0'}"
    />
  );
};

const ReferralBox = ({ translations, email }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const [hasGSLoaded, setHasGSLoaded] = useState(false);
  const [contextMenuTarget, setContextMenuTarget] = useState(null);

  const loadGS = useCallback(async () => {
    setHasGSLoaded(true);
    await window.growsurf.init();
    window.removeEventListener("grsfReady", loadGS);
  }, []);

  // Growsurf script is placed on the page by BE (application.haml via _grow_surf.erb)
  // however, that script needs to load first before we initialize the
  // embeddable form elements (referral link code and share buttons)
  // https://docs.growsurf.com/integrate/javascript-web-api/embeddable-elements
  useEffect(() => {
    if (!window.growsurf || !window.growsurf.init) {
      window.addEventListener("grsfReady", loadGS, []);
    } else {
      setHasGSLoaded(true);
      window.growsurf.init();
    }
  }, [loadGS]);

  const handleManualCopy = (event) => {
    const isShareLinkInput = event.target.classList.contains(
      REFERRAL_LINK_CLASS,
    );
    const isKeyboardCopyEvent =
      (event.ctrlKey || event.metaKey) && event.key === "c";

    if (isKeyboardCopyEvent && isShareLinkInput) {
      dispatch(analyticsManuallyCopiedReferralLink());
    }

    if (event.type === "copy" && contextMenuTarget === event.target) {
      setContextMenuTarget(null);
      dispatch(analyticsManuallyCopiedReferralLink());
    }
  };

  const handleContextMenu = (event) => {
    // We set the context menu target to the referral link input here,
    // otherwise the "copy" event fired by Growsurf would also trigger
    // the manual copy event
    if (event.target.classList.contains(REFERRAL_LINK_CLASS)) {
      setContextMenuTarget(event.target);
    }
  };

  const handleCopyButtonClick = (event) => {
    if (event.target.classList.contains(COPY_LINK_BUTTON_CLASS)) {
      dispatch(analyticsCopiedReferralLink());
    }
  };

  if (!hasGSLoaded) return null;

  return (
    <Box
      id="referral-box"
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
    >
      <Box
        p={1.5}
        bgcolor="willfulLightTeal.main"
        border={1}
        borderColor="border.main"
        borderRadius="4px"
      >
        <Box mb={1.5} display="flex">
          <Box mr={1.5} maxWidth="75%">
            <Box mb={0.5}>
              <H4 mb={0.5} align="left">
                {translations.heading}
              </H4>
            </Box>
            <SmallBody>{translations.body}</SmallBody>
          </Box>

          <Box display="flex" height={1} justifyContent="center" ml="auto">
            <PresentIcon isDesktop={isDesktop} />
          </Box>
        </Box>

        <Box>
          <H5 component="span">{translations.shareLabel}</H5>

          {/* Growsurf embeddable form elements */}
          {/* Will render a referral share link and social share buttons
              once script has loaded and been initialized */}
          <Box
            onClick={handleCopyButtonClick}
            onKeyDown={handleManualCopy}
            onContextMenu={handleContextMenu}
            onCopy={handleManualCopy}
          >
            <GrowSurfEmbeddedForm translations={translations} email={email} />
          </Box>

          <Box mt={1}>
            <MiceType color="willfulGrey1" display="block" margin="0 0 4px 0">
              {translations.referralFAQ}
              <MiceTypeLink
                color="willfulBlue"
                href={REFERRAL_PROGRAM_WILLFUL_ZENDESK_URL}
                text={translations.referralFAQLinkLabel}
                onClick={() => {
                  dispatch(
                    analyticsClickExternalLink(
                      REFERRAL_PROGRAM_WILLFUL_ZENDESK_URL,
                    ),
                  );
                }}
              />
            </MiceType>

            <MiceType color="willfulGrey1" display="block" margin="0">
              {translations.giftCardTOS}{" "}
              <MiceTypeLink
                color="willfulBlue"
                href={REFERRAL_PROGRAM_AMAZON_GIFTCARD_URL}
                text={translations.giftCardTOSLinkLabel}
                onClick={() => {
                  dispatch(
                    analyticsClickExternalLink(
                      REFERRAL_PROGRAM_AMAZON_GIFTCARD_URL,
                    ),
                  );
                }}
              />
            </MiceType>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ReferralBox;

import { combineReducers } from "redux";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const availableAddons = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return [payload.availableAddons];
    default:
      return state;
  }
};

const purchasedAddons = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.purchasedAddons;
    default:
      return state;
  }
};

export default combineReducers({
  availableAddons,
  purchasedAddons,
});

import { combineReducers } from "redux";

import { UPDATE_RECEIPT_TYPE } from "../actions/receipt";

const items = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.items;
    default:
      return state;
  }
};

const addons = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.addons;
    default:
      return state;
  }
};

const subtotalBeforeDiscounts = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.subtotalBeforeDiscounts;
    default:
      return state;
  }
};
const subtotal = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.subtotal;
    default:
      return state;
  }
};

const tax = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.tax;
    default:
      return state;
  }
};
const total = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.total;
    default:
      return state;
  }
};
const province = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.province;
    default:
      return state;
  }
};

const discounts = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_RECEIPT_TYPE:
      return payload.discounts;
    default:
      return state;
  }
};

export default combineReducers({
  items,
  subtotal,
  subtotalBeforeDiscounts,
  tax,
  total,
  province,
  discounts,
  addons,
});

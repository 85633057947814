import React from "react";
import Box from "@material-ui/core/Box";
import { H3, Body } from "../../ui/Typography";
import { ButtonLink } from "../../ui/Button";
import { DASHBOARD_PATH } from "../../../constants/routes";
import FlashMessage from "../../ui/FlashMessage";
import WhiteCard from "./WhiteCard";

const IncompleteFlow = ({
  translations,
  isDesktop,
  displayFlashMessage = true,
  flashMessageType = "warning",
  pageWarningContent,
}: ReceiptCommonProps) => {
  return (
    <WhiteCard isDesktop={isDesktop}>
      <Box mb={1}>
        <H3 align="left">{translations.incompleteHeader}</H3>
      </Box>
      <Body>{translations.incompleteBody}</Body>
      <Box mt={2}>
        <ButtonLink
          className="qa-submit qa-submit-documents"
          text={translations.button.incomplete}
          displayArrowRight
          to={DASHBOARD_PATH}
        />
      </Box>
      {displayFlashMessage && (
        <Box mt={2}>
          <FlashMessage
            type={flashMessageType}
            text={pageWarningContent}
            children={undefined}
          />
        </Box>
      )}
    </WhiteCard>
  );
};

export default IncompleteFlow;

import R from "ramda";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reduxForm,
  Field,
  FieldArray,
  getFormValues,
  getFormSubmitErrors,
} from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { Form } from "../../ui/Form";

import TextInput from "../../ui/inputs/TextInput";
import { ErrorMessage } from "../../ui/Error";
import {
  useValidateContactPresentMemo,
  useValidateGiftDescriptionMemo,
  useValidateGiftDescriptionMemoQC,
} from "../../ui/inputs/validations";
import { Body, SmallBody, SmallExternalLink } from "../../ui/Typography";
import { updateGiftsOnProgress, removeGifts } from "../../../actions/gifts";
import { selectGiftContactModalClosed } from "../../../selectors/modal";

import {
  CONTACT_ID_FIELD_ID,
  GIFTS_FIELD_ID,
  GIFTS_FORM_ID,
  GIFT_DESCRIPTION_FIELD_ID,
} from "../../../constants/forms";
import { selectIsCurrentProvinceSelectedByCode } from "../../../selectors/provinces";
import { selectAllContacts } from "../../../selectors/contacts";
import { openModalContactForContactable } from "../../../actions/contacts";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import { MODAL_ADD_CONTACT_GIFT } from "../../../constants/modal";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";

export const GiftFieldArrayRenderer = ({
  fields,
  translations,
  defaultFieldObject = {},
  canAddMore = true,
  helperText,
  hasIncompleteFields,
  handleFocus,
  handleBlur,
}) => {
  const dispatch = useDispatch();
  const allFields = fields.getAll() || [];
  const addLabel =
    allFields.filter((field) => field._destroy !== true).length === 0
      ? translations.button.addLabel
      : translations.button.addMoreLabel;
  return (
    <Box>
      {fields.map((fieldId, index) => {
        const field = fields.get(index);
        const fieldGiftId = field?.id;
        const fieldContactId = field?.contact?.id;
        const fieldDescription = field?.description;
        // _destroy indicates to rails that field is to be deleted
        // requires rerenderOnEveryChange={true} on <FieldArray />
        // performance hit on large lists < 10 should be fine
        if (field && field._destroy === true) {
          return null;
        }
        const handleRemove = () => {
          dispatch(removeGifts(field));
        };
        return (
          <Box key={fieldId}>
            <Gift
              id={fieldId}
              fieldGiftId={fieldGiftId}
              fieldIndex={index}
              translations={translations}
              initialContactId={fieldContactId}
              initialDescription={fieldDescription}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
            />
            <Box my={2} width="100%" display="flex" flexDirection="row-reverse">
              <SmallTextButton
                text={translations.button.removeLabel}
                className="qa-remove"
                onClick={handleRemove}
              />
            </Box>
          </Box>
        );
      })}
      {helperText && <Body>{helperText}</Body>}
      {canAddMore && !hasIncompleteFields && (
        <SecondaryButton
          text={addLabel}
          fullWidth
          displayPlusIcon
          className="qa-add"
          type="button" // required else it defaults to submit which triggers form validation right after adding something
          onClick={() => fields.push(defaultFieldObject)}
        />
      )}
    </Box>
  );
};

const Gift = ({
  id,
  fieldGiftId,
  fieldIndex,
  translations,
  initialContactId = null,
  initialDescription = "",
  handleFocus,
  handleBlur,
}) => {
  const [selectedContactId, setSelectedContactId] = useState(initialContactId);
  const [selectedGiftDescription, setSelectedGiftDescription] = useState(
    initialDescription,
  );
  const [lastSavedDescription, setLastSavedDescription] = useState({}); // Track last saved
  const handleSelectContact = (contactId) => {
    setSelectedContactId(contactId);
  };
  const handleGiftDescriptionChange = (value) => {
    setSelectedGiftDescription(value);
  };
  const giftContactModal = useSelector(selectGiftContactModalClosed);

  useEffect(() => {
    // when initial contactId is updated, update selectedContactId
    if (initialContactId) {
      setSelectedContactId(initialContactId);
    }
    if (initialDescription) {
      setSelectedGiftDescription(initialDescription);
    }
  }, [initialContactId, initialDescription]);

  useEffect(() => {
    if (giftContactModal && selectedContactId) {
      dispatchGiftUpdate();
    }
  }, [giftContactModal, dispatchGiftUpdate, selectedContactId]);

  const isQuebecSelected = useSelector(
    selectIsCurrentProvinceSelectedByCode("QC"),
  );
  const validationFunctionMemo = isQuebecSelected
    ? useValidateGiftDescriptionMemoQC
    : useValidateGiftDescriptionMemo;
  const dispatch = useDispatch();
  const contacts = useSelector(selectAllContacts);
  const handleAddNewContact = (contactableId) => {
    dispatch(
      openModalContactForContactable(
        null,
        MODAL_ADD_CONTACT_GIFT,
        contactableId,
        false,
        GIFTS_FORM_ID,
        GIFTS_FIELD_ID,
        fieldIndex,
      ),
    );
  };

  const dispatchGiftUpdate = useCallback(() => {
    const lastSaved = lastSavedDescription[fieldIndex] || "";
    if (selectedContactId && !lastSaved.includes(selectedGiftDescription)) {
      dispatch(
        updateGiftsOnProgress(
          selectedContactId,
          selectedGiftDescription,
          fieldGiftId || null,
          fieldIndex,
        ),
      );
      setLastSavedDescription((prev) => ({
        ...prev,
        [fieldIndex]: [...(prev[fieldIndex] || []), selectedGiftDescription],
      }));
    }
  }, [
    dispatch,
    fieldGiftId,
    selectedContactId,
    selectedGiftDescription,
    lastSavedDescription,
    fieldIndex,
  ]);

  const handleGiftBlur = (fieldName, value) => {
    if (fieldName === "description" && value && selectedContactId) {
      dispatchGiftUpdate();
    }
    handleBlur();
  };
  const handleOnSelect = (contactId) => {
    handleSelectContact(contactId);
    if (contactId && selectedGiftDescription) {
      dispatch(
        updateGiftsOnProgress(
          contactId,
          selectedGiftDescription,
          fieldGiftId,
          fieldIndex,
        ),
      );
    }
  };
  return (
    <Box>
      <Box mb={1}>
        <DropdownContactSelect
          name={`${id}.${CONTACT_ID_FIELD_ID}`}
          label={translations.giftRecipientLabel}
          contacts={contacts}
          selectedContactId={selectedContactId}
          onSelect={handleOnSelect}
          onAddNew={handleAddNewContact}
          validator={useValidateContactPresentMemo(translations)}
          includeMinorChildren
        />
      </Box>
      <Field
        name={`${id}.${GIFT_DESCRIPTION_FIELD_ID}`}
        component={TextInput}
        validate={validationFunctionMemo(translations)}
        label={translations.giftDescriptionLabel}
        placeholder={translations.giftPlaceholder}
        onChange={(e) => handleGiftDescriptionChange(e.target.value)}
        onBlur={(e) => handleGiftBlur("description", e.target.value)} // Dispatch action on blur
        onFocus={() => handleFocus(fieldIndex)}
      />
    </Box>
  );
};

const BequestOfRRSP = ({ translations }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Box mt={1.5} textAlign="center">
          <SmallBody margin="16px" align="center">
            {translations.bequestOfRRSP}
          </SmallBody>
          <SmallExternalLink
            target="_blank"
            href={translations.bequestLink}
            text={translations.bequestLinkText}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

const AssetListNote = ({ translations }) => {
  return (
    <Grid container justify="center" spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Box my={1.5} textAlign="center">
          <SmallBody align="center">{translations.assetListNote}</SmallBody>
        </Box>
      </Grid>
    </Grid>
  );
};

const GiftsHeaderComponent = ({
  requiresBequestOfRRSP,
  translations,
  isPremiumProvinceSelected,
  isCouplesPlan,
}) => {
  if (requiresBequestOfRRSP) {
    return <BequestOfRRSP translations={translations} />;
  }
  if (isPremiumProvinceSelected && !isCouplesPlan) {
    return <AssetListNote translations={translations} />;
  }
  return null;
};

const GiftsForm = ({
  error,
  handleSubmit,
  backLink,
  onSkip,
  change,
  isLoading,
  requiresBequestOfRRSP,
  translations,
  isPremiumProvinceSelected,
  isCouplesPlan,
}) => {
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const formData = useSelector(getFormValues(GIFTS_FORM_ID));
  const gifts = R.propOr([], GIFTS_FIELD_ID)(formData);
  const [focusedGiftIndex, setFocusedGiftIndex] = useState(null);
  const handleFocus = (index) => {
    setFocusedGiftIndex(index);
  };
  const handleBlur = () => {
    setFocusedGiftIndex(null);
  };
  const hasIncompleteFields = gifts.some(
    (gift) => (!gift.contactId || !gift.id) && !gift.description,
  );
  const submitErrors = useSelector(getFormSubmitErrors(GIFTS_FORM_ID));
  const hasSubmitErrors = submitErrors[GIFTS_FIELD_ID]?.length > 0;
  const isButtonDisabled =
    hasSubmitErrors || hasIncompleteFields || focusedGiftIndex !== null;
  return (
    <Form
      qaFormId="gifts"
      isLoading={isLoading}
      onSubmit={handleSubmit}
      HeaderComponent={GiftsHeaderComponent}
      requiresBequestOfRRSP={requiresBequestOfRRSP}
      isPremiumProvinceSelected={isPremiumProvinceSelected}
      isCouplesPlan={isCouplesPlan}
      backLink={backLink}
      onSkip={onSkip}
      translations={translations}
      reverseWrap={isTabletDown}
      disabled={isButtonDisabled}
    >
      <FieldArray
        rerenderOnEveryChange
        handleChange={change}
        name={GIFTS_FIELD_ID}
        component={GiftFieldArrayRenderer}
        translations={translations}
        hasIncompleteFields={hasIncompleteFields}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
      />
      <Box mb={2}>
        <ErrorMessage error={error} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: GIFTS_FORM_ID,
})(GiftsForm);

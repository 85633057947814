export const updateGifts = () => ({
  type: UPDATE_GIFTS_TYPE,
});

export const updateGiftsWithContactsAction = (payload: GiftFormPayload[]) => ({
  type: UPDATE_GIFTS_WITH_CONTACT_TYPE,
  payload,
});

export const openModalGiftContact = (
  contact: ContactModalData,
  modalKey: string,
) => ({
  type: OPEN_MODAL_CONTACT_GIFT_TYPE,
  payload: { contact, modalKey },
});

export const openModalContactForContactable = (
  contact: ContactModalData,
  modalKey: string,
  contactableId: number,
  isSecondaryContact: boolean = false,
) => ({
  type: OPEN_MODAL_CONTACT_GIFT_TYPE,
  payload: { contact, modalKey, contactableId, isSecondaryContact },
});

export const updateGiftsOnProgress = (
  contactId: number,
  description: string,
  giftId: number,
  fieldIndex: number,
) => {
  return {
    type: UPDATE_GIFTS_ON_PROGRESS_TYPE,
    payload: {
      description,
      contactId,
      giftId,
      fieldIndex,
    },
  };
};

export const removeGifts = (payload: GiftFormPayload[]) => ({
  type: REMOVE_GIFTS_TYPE,
  payload,
});

export const giftNextPage = () => ({
  type: GIFT_NEXT_PAGE_TYPE,
});

export const UPDATE_GIFTS_TYPE = "UPDATE_GIFTS";
export const UPDATE_GIFTS_WITH_CONTACT_TYPE = "UPDATE_GIFTS_WITH_CONTACT";
export const OPEN_MODAL_CONTACT_GIFT_TYPE = "OPEN_MODAL_CONTACT_GIFT";
export const UPDATE_GIFTS_ON_PROGRESS_TYPE = "UPDATE_GIFTS_ON_PROGRESS";
export const REMOVE_GIFTS_TYPE = "REMOVE_GIFTS";
export const GIFT_NEXT_PAGE_TYPE = "GIFT_NEXT_PAGE";

export const PURCHASE_DIGITAL_EXECUTION_ACTION = "PURCHASE_DIGITAL_EXECUTION";
export const BOOK_DIGITAL_EXECUTION_ACTION = "BOOK_DIGITAL_EXECUTION";

export const purchaseDigitalExecutionAction = () => ({
  type: PURCHASE_DIGITAL_EXECUTION_ACTION,
});

export const bookDigitalExecutionAction = () => ({
  type: BOOK_DIGITAL_EXECUTION_ACTION,
});

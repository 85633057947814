import React from "react";
import Box from "@material-ui/core/Box";
import IncompleteFlow from "./IncompleteFlow";
import YourDocuments from "./YourDocuments";
import InviteToPlan from "./InviteToPlan";

const ReceiptInfoLeftGridContent = ({
  isCompleteAllSections,
  translations,
  isNotarialWill,
  isDesktop,
  pageWarningContent,
  bundlePlanSelected,
}: ReceiptInfoLeftGridContentProps) => {
  return (
    <Box mt={2}>
      {isCompleteAllSections ? (
        <YourDocuments
          translations={translations}
          isNotarialWill={isNotarialWill}
          isDesktop={isDesktop}
          pageWarningContent={pageWarningContent}
        />
      ) : (
        <>
          <IncompleteFlow
            translations={translations}
            isDesktop={isDesktop}
            pageWarningContent={pageWarningContent}
          />
        </>
      )}
      {bundlePlanSelected && (
        <InviteToPlan translations={translations} isDesktop={isDesktop} />
      )}
    </Box>
  );
};

export default ReceiptInfoLeftGridContent;

import { createStructuredSelector, createSelector } from "reselect";

import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { referralProgramProps } from "./referral-program";
import { selectTranslations } from "./translations";
import { selectIsCompleteAllSections } from "./section-completion";
import {
  selectOrderTaxType,
  selectOrderTaxFormatted,
  selectIsOrderPlanBundle,
  selectOrderTotalFormatted,
  selectOrderValueFormatted,
  selectOrderPlanLineItemFormatted,
} from "./utils/order";
import {
  selectOrderHasDiscount,
  selectOrderDiscountsData,
  selectOrderPromoCode,
} from "./utils/discounts";
import { selectIsUserFromBC } from "./provinces";
import { selectHasActivePlan, selectIsNotarialWill } from "./plans";
import { selectPlanSummaryProps } from "./dashboard";
import { selectIsFrenchSelected, selectLanguageCode } from "./language";
import { formatCurrency } from "../utilities/helpers";

const selectReceiptState = (state) => state.receipt;

const selectReceiptTranslations = createSelector(
  selectTranslations(["global", "checkout", "receipt"]),
  selectOrderPlanLineItemFormatted("receipt"),
  selectIsFrenchSelected,
  (receiptTranslations, planLineItem) => {
    return {
      ...receiptTranslations,
      planName: planLineItem,
    };
  },
);

export const selectAddonsReceiptFormatted = createSelector(
  selectReceiptState,
  selectLanguageCode,
  (receipt, languageCode) => {
    return receipt.addons?.map((addon) => {
      const priceFormatted = formatCurrency(addon.totalPrice, languageCode, 2);
      return {
        id: addon.id,
        quantity: addon.quantity,
        price: priceFormatted,
        name: addon.addon.name,
        symbol: addon.addon.symbol,
      };
    });
  },
);

export const isAddonReceipt = createSelector(selectReceiptState, (receipt) => {
  return receipt.addons?.length > 0;
});

export const receiptPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById("receipt"),
  isLoading: selectIsLoadingById("receipt"),
  tax: selectOrderTaxFormatted("receipt"),
  taxType: selectOrderTaxType("receipt"),
  total: selectOrderTotalFormatted("receipt"),
  orderValue: selectOrderValueFormatted("receipt"),
  hasDiscount: selectOrderHasDiscount("receipt"),
  discountsData: selectOrderDiscountsData("receipt"),
  promoCode: selectOrderPromoCode("receipt"),
  translations: selectReceiptTranslations,
  isCompleteAllSections: selectIsCompleteAllSections,
  bundlePlanSelected: selectIsOrderPlanBundle("receipt"),
  documentsTranslations: selectTranslations(["documents"]),
  isUserFromBC: selectIsUserFromBC,
  isNotarialWill: selectIsNotarialWill,
  hasPlan: selectHasActivePlan,
  planSummaryProps: selectPlanSummaryProps,
  referralProgramProps,
});

import { useEffect, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }

    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey]);

  return keyPressed;
}

export function useBackgroundLock(modalEl) {
  // Code to handle management of target element ref adapted from https://github.com/chakra-ui/chakra-ui/issues/712
  const [targetElement, setTargetElement] = useState(null);

  useEffect(() => {
    setTargetElement(modalEl.current);
  }, [modalEl]);

  useEffect(() => {
    if (targetElement) {
      disableBodyScroll(targetElement, {
        reserveScrollBarGap: true,
      });
    }

    return function cleanup() {
      if (targetElement) {
        enableBodyScroll(targetElement);
      }
    };
  }, [targetElement]);
}

export function useMenuToggle(closeTime = 120) {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const handleToggle = (shouldOpen) => {
    if (shouldOpen) {
      setIsOpen(true);
    }
    if (shouldOpen === false) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, closeTime);
    }
  };

  return {
    handleToggle,
    isOpen,
    isClosing,
  };
}

export function useBackgroundClickClose(ref, isOpen, handleClose) {
  useEffect(() => {
    const handleClick = (e) => {
      if (!ref.current) return;
      if (!ref.current.contains(e.target)) {
        handleClose();
      }
    };

    if (isOpen) {
      document.body.addEventListener("click", handleClick);
    }
    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, [handleClose, isOpen, ref]);
}

import React from "react";
import Box from "@material-ui/core/Box";
import { ButtonLink } from "../../ui/Button";
import { YOUR_ACCOUNT_PATH } from "../../../constants/routes";
import { Body, H3 } from "../../ui/Typography";
import WhiteCard from "./WhiteCard";

const InviteToPlan = ({ translations, isDesktop }: ReceiptCommonProps) => {
  return (
    <WhiteCard isDesktop={isDesktop} includeBottomMargin={false}>
      <Box mb={1}>
        <H3 align="left">{translations.inviteHeader}</H3>
      </Box>
      <Body>{translations.inviteBody}</Body>
      <Box mt={2}>
        <ButtonLink
          className="qa-submit qa-submit-invite"
          text={translations.button.invitePeople}
          displayArrowRight
          to={YOUR_ACCOUNT_PATH}
        />
      </Box>
    </WhiteCard>
  );
};

export default InviteToPlan;

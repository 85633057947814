import { createSelector } from "reselect";
import R from "ramda";

const addonState = R.pathOr(0, ["addons"]);
// Hardcoding this [0] for now because we only have one available addon,
// we will need to update the design and map through the array if we do have multiple available addon to purchase
export const selectAvailableAddons = createSelector(addonState, (state) => {
  return state.availableAddons[0];
});

export const selectPurchasedAddons = createSelector(addonState, (state) => {
  return state.purchasedAddons || [];
});

import React, { useContext, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import styled from "styled-components";
import { selectTranslations } from "../../selectors/translations";
import { MODAL_DIGITAL_EXECUTION } from "../../constants/modal";
import { Body } from "../ui/Typography";
import { addonIcons } from "../addons/AddonIcons";
import ModalContext from "../../contexts/ModalContext";
import { featureMobileDrawerEnabled } from "../../utilities/featureFlags";
import ToggleButton from "../ui/ToggleButton";
import { colorPalette } from "../../config/Theme";
import { addAddon, removeAddon } from "../../actions/cart";
import { selectAddonCount } from "../../selectors/cart";
import { selectAvailableAddons } from "../../selectors/addons";

const YellowBox = styled(Box)`
  background-color: ${colorPalette.yellow};
  padding: 8px;
  display: inline-block;
  font-size: 14px;
  margin-top: 20px;
`;

const PriceBox = styled(Box)`
  font-size: ${({ isDesktop }) => (isDesktop ? "22px" : "16px")};
`;

export const DigitalExecutionModal = ({ closeModal }) => {
  const { setMobileDrawer } = useContext(ModalContext);
  const translations = useSelector(selectTranslations(["global", "notice"]))[
    MODAL_DIGITAL_EXECUTION
  ];
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const addonIcon = addonIcons.digitalExecutionAddon.icon;

  const availableAddon = useSelector(selectAvailableAddons);
  const isAddonInCart = useSelector(selectAddonCount) > 0;

  useEffect(() => {
    setMobileDrawer(featureMobileDrawerEnabled());
  }, [setMobileDrawer]);

  const handleClick = useCallback(() => {
    if (!isAddonInCart) {
      dispatch(addAddon({ addons: [{ id: availableAddon.id, quantity: 1 }] }));
    } else {
      dispatch(removeAddon({ addon_id: availableAddon.id }));
    }
    closeModal();
  }, [isAddonInCart, dispatch, availableAddon.id, closeModal]);

  return (
    <div id="modal--digital-execution">
      <Box mb={1}>
        <YellowBox>{translations.infoLabel}</YellowBox>
      </Box>
      <Box mb={1}>
        <PriceBox isDesktop={isDesktop}>{translations.infoPrice}</PriceBox>
      </Box>
      <Body mb={isTabletDown ? 2 : 1} display={!isTabletDown && "flex"}>
        {translations.body}
      </Body>
      <Box mb={2} display="flex" justifyContent="center" ml="auto">
        {addonIcon}
      </Box>
      <Box display={isDesktop && "flex"}>
        <ToggleButton
          stage={isAddonInCart}
          lineTrue={translations.inCart}
          lineFalse={translations.toCart}
          onClick={handleClick}
        />
      </Box>
    </div>
  );
};

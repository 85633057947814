export const fetchCart = () => ({
  type: FETCH_CART_TYPE,
});

export const updateCart = (cart) => ({
  type: UPDATE_CART_TYPE,
  payload: cart,
});

export const addAddon = (addons) => ({
  type: ADD_ADDON_TYPE,
  payload: addons,
});

export const removeAddon = (addons) => ({
  type: REMOVE_ADDON_TYPE,
  payload: addons,
});

export const clearCart = () => ({
  type: CLEAR_CART_TYPE,
});

export const toggleCartWidget = (flag) => ({
  type: TOGGLE_CART_WIDGET,
  payload: flag,
});

export const FETCH_CART_TYPE = "FETCH_CART";
export const UPDATE_CART_TYPE = "UPDATE_CART";
export const CLEAR_CART_TYPE = "CLEAR_CART";
export const ADD_ADDON_TYPE = "ADD_ADDON";
export const REMOVE_ADDON_TYPE = "REMOVE_ADDON";
export const TOGGLE_CART_WIDGET = "TOGGLE_CART_WIDGET";

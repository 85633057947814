import { getRequest, putRequest, deleteRequest } from "./request";
import { GIFTS_ENDPOINT, GIFTS_NEXT_PAGE_ENDPOINT } from "../constants/routes";

export const submitGifts = (config) => putRequest(GIFTS_ENDPOINT, config);
export const skipGifts = (config) => deleteRequest(GIFTS_ENDPOINT, config);
export const submitGiftsOnProgress = (config) =>
  putRequest(GIFTS_ENDPOINT, config);
export const removeGifts = (config) => deleteRequest(GIFTS_ENDPOINT, config);
export const getGiftsNextPage = (config) =>
  getRequest(GIFTS_NEXT_PAGE_ENDPOINT, config);

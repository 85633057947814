import { createSelector } from "reselect";

import { selectPurchasedAddons } from "./addons";

import {
  DIGITAL_EXECUTION_TYPE,
  DIGITAL_EXECUTION_PURCHASED,
  DIGITAL_EXECUTION_NOT_PURCHASED,
} from "../constants/addons";

export const selectDigitalExecutionStage = createSelector(
  selectPurchasedAddons,
  (purchasedAddons) => {
    const hasPurchasedDigitalExecution = purchasedAddons.some(
      (addon) => addon.name === DIGITAL_EXECUTION_TYPE,
    );

    if (hasPurchasedDigitalExecution) {
      return DIGITAL_EXECUTION_PURCHASED;
    }

    return DIGITAL_EXECUTION_NOT_PURCHASED;
  },
);

import React from "react";

import DigitalExecutionAddon from "../ui/icons/DigitalExecutionAddon";

export const addonIcons = {
  digitalExecutionAddon: {
    icon: <DigitalExecutionAddon />,
    iconSmall: <DigitalExecutionAddon width={88} height={88} />,
    iconExtraSmall: <DigitalExecutionAddon width={60} height={60} />,
    cartIcon: <DigitalExecutionAddon width={44} height={48} />,
  },
};

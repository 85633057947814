import React from "react";
import Box from "@material-ui/core/Box";
import { DOCUMENTS_PATH } from "../../../constants/routes";
import { H3, Body } from "../../ui/Typography";
import { ButtonLink } from "../../ui/Button";
import FlashMessage from "../../ui/FlashMessage";
import WhiteCard from "./WhiteCard";

const YourDocuments = ({
  translations,
  isNotarialWill,
  isDesktop,
  displayFlashMessage = true,
  flashMessageType = "warning",
  pageWarningContent,
}: ReceiptCommonProps) => {
  return (
    <WhiteCard isDesktop={isDesktop}>
      <Box mb={1}>
        <H3 align="left">
          {isNotarialWill
            ? translations.viewYourDocumentsHeader
            : translations.downloadYourDocumentsHeader}
        </H3>
      </Box>
      <Body>
        {isNotarialWill
          ? translations.yourDocumentsNotarialBody
          : translations.yourDocumentsBody}
      </Body>
      <Box mt={2}>
        <ButtonLink
          className="qa-submit qa-submit-documents"
          text={translations.button.viewDocuments}
          displayArrowRight
          to={DOCUMENTS_PATH}
        />
      </Box>
      {displayFlashMessage && (
        <Box mt={2}>
          <FlashMessage
            type={flashMessageType}
            text={pageWarningContent}
            children={undefined}
          />
        </Box>
      )}
    </WhiteCard>
  );
};

export default YourDocuments;

import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { SmallTextButton } from "./Button";
import { CONTACTS_PATH } from "../../constants/routes";
import { selectTranslations } from "../../selectors/translations";
import SendIcon from "./icons/SendIcon";
import { analyticsNotifyAppointeeIntent } from "../../actions/analytics";

const NotifyContactWrapper = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    position: relative;
    top: 1px;
  }
`;

const NotifyContactButton = ({ hasMargin, className = "" }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const translations = useSelector(selectTranslations(["navigation"]));
  const notifyContactClass = className || "qa-notify-contact";

  const handleClick = () => {
    dispatch(analyticsNotifyAppointeeIntent());
    history.push(CONTACTS_PATH);
  };

  return (
    <NotifyContactWrapper mr={hasMargin && 1.5} onClick={handleClick}>
      <SendIcon />
      <Box ml={0.5}>
        <SmallTextButton
          text={translations.notifyContact}
          className={notifyContactClass}
        />
      </Box>
    </NotifyContactWrapper>
  );
};

export default NotifyContactButton;

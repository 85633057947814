import React from "react";
import { withTheme } from "@material-ui/styles";
import classNames from "classnames";
import { Body } from "./Typography";
import PlusIcon from "./icons/PlusIcon";
import CheckmarkBlue from "./icons/CheckmarkBlue";
import { StyledPrimaryButton, StyledSecondaryButton } from "./Button";

export const TogglePrimaryButtonComponent = ({
  text,
  displayPlusIcon = true,
  type = "submit",
  loadingLabel,
  destructive = false,
  displayArrowLeft,
  displayArrowRight,
  isLoading,
  isDisabled = false,
  ...otherProps
}) => (
  <StyledPrimaryButton
    disabled={isLoading || isDisabled}
    className={classNames("qa-submit", { isLoading }, { isDisabled })}
    destructive={destructive}
    type={type}
    id={otherProps.id ? otherProps.id : `${type}Button`} // for testing purpose
    {...otherProps}
  >
    {displayArrowLeft && (
      <Body component="span" color={"yellow"} className="arrow left">
        ←
      </Body>
    )}

    <Body component="span" color={"white"} className="default-margin">
      {displayPlusIcon && <PlusIcon />} {isLoading ? loadingLabel : text}
    </Body>
    {displayArrowRight && !isLoading && (
      <Body
        component="span"
        color={destructive ? "white" : "yellow"}
        className="arrow right"
      >
        →
      </Body>
    )}
  </StyledPrimaryButton>
);

export const TogglePrimaryButton = withTheme(TogglePrimaryButtonComponent);

export const ToggleSecondaryButtonComponent = ({
  text,
  displayCheckmark = true,
  type = "submit",
  loadingLabel,
  isLoading,
  displayArrowLeft,
  displayArrowRight,
  displayPlusIcon,
  isDisabled = false,
  ...otherProps
}) => (
  <StyledSecondaryButton
    disabled={isLoading || isDisabled}
    className={classNames("qa-secondary", { isLoading }, { isDisabled })}
    type={type}
    {...otherProps}
  >
    {displayArrowLeft && (
      <Body component="span" color="main" className="arrow left">
        ←
      </Body>
    )}
    <Body
      component="span"
      color={isDisabled ? "secondary" : "main"}
      align="center"
      className="default-margin"
    >
      {displayCheckmark && <CheckmarkBlue />}
      {isLoading ? loadingLabel : text}
    </Body>
    {displayArrowRight && (
      <Body component="span" color="main" className="arrow right">
        →
      </Body>
    )}
    {displayPlusIcon && <PlusIcon />}
  </StyledSecondaryButton>
);

export const ToggleSecondaryButton = withTheme(ToggleSecondaryButtonComponent);

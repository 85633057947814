import R from "ramda";
import { all, takeEvery, call, put, select } from "redux-saga/effects";
import { getFormValues, startSubmit, change } from "redux-form";
import {
  UPDATE_GIFTS_TYPE,
  UPDATE_GIFTS_WITH_CONTACT_TYPE,
  UPDATE_GIFTS_ON_PROGRESS_TYPE,
  GIFT_NEXT_PAGE_TYPE,
  REMOVE_GIFTS_TYPE,
} from "../actions/gifts";
import { submitFormRequest } from "./forms";
import {
  submitGifts,
  getGiftsNextPage,
  submitGiftsOnProgress,
  removeGifts,
} from "../api/gifts";
import { GIFTS_FORM_ID, GIFTS_FIELD_ID } from "../constants/forms";
import { getFormData } from "../actions/forms";

export function* updateGifts() {
  yield call(submitFormRequest, {
    apiCall: submitGifts,
    formId: GIFTS_FORM_ID,
  });
}

export function* updateGiftsWithContactWorker() {
  yield call(submitFormRequest, {
    apiCall: submitGifts,
    formId: GIFTS_FORM_ID,
  });
}

export function* updateGiftsOnProgress({ payload }) {
  const description = R.propOr("", "description")(payload);
  const contactId = R.propOr(null, "contactId")(payload);
  const giftId = R.propOr(null, "giftId")(payload);
  const fieldIndex = R.propOr(null, "fieldIndex")(payload);
  yield put(startSubmit(GIFTS_FORM_ID));
  if (contactId && description) {
    const response = yield call(submitFormRequest, {
      apiCall: submitGiftsOnProgress,
      formId: GIFTS_FORM_ID,
      values: {
        description,
        contactId,
        fieldIndex,
        id: giftId,
      },
    });

    const { gift } = response;
    if (response.status === 200 && gift) {
      yield put(getFormData(GIFTS_FORM_ID));
    }
  }
}

export function* handleRemoveGift({ payload }) {
  const giftId = payload.id;

  // only makes the request if the gift has an id
  if (giftId) {
    yield call(submitFormRequest, {
      apiCall: removeGifts,
      values: { giftId },
    });
  }

  const formData = yield select(getFormValues(GIFTS_FORM_ID));
  const giftsList = formData?.gifts || [];
  const updatedGiftsList = giftsList.filter((gift) => gift.id !== giftId);
  yield put(change(GIFTS_FORM_ID, GIFTS_FIELD_ID, updatedGiftsList));
}

export function* handleGiftsNextPage() {
  return yield call(submitFormRequest, {
    apiCall: getGiftsNextPage,
  });
}

function* watchUpdateGifts() {
  yield takeEvery(UPDATE_GIFTS_TYPE, updateGifts);
}

function* watchUpdateGiftsWithContact() {
  yield takeEvery(UPDATE_GIFTS_WITH_CONTACT_TYPE, updateGiftsWithContactWorker);
}

function* watchUpdateGiftsOnProgress() {
  yield takeEvery(UPDATE_GIFTS_ON_PROGRESS_TYPE, updateGiftsOnProgress);
}

function* watchRemoveGifts() {
  yield takeEvery(REMOVE_GIFTS_TYPE, handleRemoveGift);
}

function* watchGiftsNextPage() {
  yield takeEvery(GIFT_NEXT_PAGE_TYPE, handleGiftsNextPage);
}

export function* watchGiftSaga() {
  yield all([
    watchUpdateGifts(),
    watchUpdateGiftsWithContact(),
    watchUpdateGiftsOnProgress(),
    watchRemoveGifts(),
    watchGiftsNextPage(),
  ]);
}

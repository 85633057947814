import React from "react";
import Box from "@material-ui/core/Box";

const WhiteCard = ({
  children,
  isDesktop,
  includeBottomMargin = true,
  ...restOfProps
}: WhiteCardProps) => (
  <Box
    bgcolor="willfulWhite.main"
    border={1}
    borderRadius={4}
    borderColor="border.main"
    p={isDesktop ? 2 : 1.5}
    mb={includeBottomMargin && 2}
    {...restOfProps}
  >
    {children}
  </Box>
);

export default WhiteCard;

import React from "react";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { errorMessageTranslations } from "../../selectors/translations";

import { SmallBody, SmallTextLink } from "./Typography";
import styled from "styled-components";

export const ErrorTextLink = styled(SmallTextLink)`
  padding-left: 6px;
`;
export const ErrorMessageComponent = ({
  error,
  translations,
  shouldDisplayError = true,
  align = "left",
  color = "error",
  showHTMLErrorMessage = false,
  linkUrl,
  linkText,
}) => {
  if (shouldDisplayError && error) {
    return (
      <Box mt={0.5}>
        {showHTMLErrorMessage ? (
          <SmallBody
            align={align}
            color={color}
            dangerouslySetInnerHTML={{ __html: error }}
          />
        ) : (
          <SmallBody align={align} color={color}>
            {translations[error] || error}

            {linkUrl && linkText && (
              <ErrorTextLink to={linkUrl} text={linkText} />
            )}
          </SmallBody>
        )}
      </Box>
    );
  }
  return null;
};

export const ErrorMessage = connect(errorMessageTranslations)(
  ErrorMessageComponent,
);

import React from "react";
import styled from "styled-components";
import { ToggleSecondaryButton, TogglePrimaryButton } from "./ButtonWithIcon";

const TogglePrimary = styled(TogglePrimaryButton)`
  width: 100%;

  svg {
    margin-bottom: -5px;
  }
`;

const ToggleSecondary = styled(ToggleSecondaryButton)`
  width: 100%;

  svg {
    margin-bottom: -5px;
    margin-right: 4px;
  }
`;

export default function ToggleButton({
  stage,
  lineTrue,
  lineFalse,
  onClick,
  ...otherProps
}) {
  if (stage) {
    return (
      <ToggleSecondary onClick={onClick} text={lineTrue} {...otherProps} />
    );
  }

  return <TogglePrimary onClick={onClick} text={lineFalse} {...otherProps} />;
}

import React from "react";
import SvgIcon from "./SvgIcon";

const SendIcon = ({ color = "#0064DC", width = 20, height = 20 }) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1042 2.0015C19.2327 1.68545 19.1594 1.32318 18.9181 1.08193C18.6769 0.840687 18.3146 0.767392 17.9985 0.895886L1.36228 7.65951C1.04206 7.7897 0.832819 8.10113 0.833333 8.44681C0.833847 8.79249 1.04401 9.1033 1.36462 9.23254L8.06613 11.9339L10.7675 18.6354C10.8967 18.956 11.2076 19.1662 11.5532 19.1667C11.8989 19.1672 12.2103 18.958 12.3405 18.6378L19.1042 2.0015ZM8.51497 10.2851L3.9431 8.44219L14.7526 4.0475L8.51497 10.2851ZM9.71495 11.4851L11.5579 16.0569L15.9525 5.24756L9.71495 11.4851Z"
    />
  </SvgIcon>
);

export default SendIcon;

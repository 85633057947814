import R from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, FieldArray } from "redux-form";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { Form } from "../../ui/Form";
import BinaryRadioField from "../../ui/inputs/BinaryRadioField";
import { ErrorMessage } from "../../ui/Error";
import { useValidateSpouseIsExecutorMemo } from "../../ui/inputs/validations";
import { Body, SmallBody, SmallExternalLink } from "../../ui/Typography";

import {
  EXECUTORS_FORM_ID,
  EXECUTORS_FIELD_ID,
  SPOUSE_IS_EXECUTOR_FIELD_ID,
} from "../../../constants/forms";
import {
  selectAllContacts,
  selectSpouseContact,
} from "../../../selectors/contacts";
import {
  openModalContactForContactable,
  removeSecondaryContact,
  selectContactFromDropdown,
} from "../../../actions/contacts";
import { MODAL_ADD_CONTACT_EXECUTOR } from "../../../constants/modal";
import { EXECUTOR_CONTACTABLE_MODEL } from "../../../constants/contacts";
import { removeExecutors } from "../../../actions/executors";
import DropdownContactSelect from "../../ui/inputs/DropdownContactSelect";
import AddCoFieldButton from "../../ui/AddCoFieldButton";
import { SecondaryButton, SmallTextButton } from "../../ui/Button";
import { selectExecutors } from "../../../selectors/executors";
import NotifyContactButton from "../../ui/NotifyContactButton";
import { selectHasUserPaid } from "../../../selectors/plans";

const ProvincialWarning = ({ text, link, addInfo }) => {
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={8} lg={6}>
        <Box mt={1.5} textAlign="center">
          <SmallBody margin="16px" align="center">
            {text}
          </SmallBody>
          {link && (
            <SmallExternalLink target="_blank" href={link} text={addInfo} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const getExecutorLevelTranslation = (
  appointeeLevelIndexs,
  index = 0,
  translations,
  spouseIsPrimary = false,
  isCoExecutor = false,
) => {
  let level = R.pathOr(null, [index, "level"])(appointeeLevelIndexs);
  if (level === null) {
    return "";
  }
  if (spouseIsPrimary) {
    // increases appointeeLevel if spouse is selected as primary appointee
    // so that the first backup appointee is declared as the "Secondary" appointee
    level += 1;
  }
  const executorsText = isCoExecutor
    ? translations.coExecutorTitle
    : translations.executorTitle;
  return `${translations.levels[level]} ${executorsText}`;
};

const ExecutorsDropdownFieldArray = ({
  fields,
  translations,
  handleChange,
  appointeeLevelIndexs = {},
  isTabletDown,
  subQuestionText = "",
  subQuestionOptionalText = "",
  hasSpouse = false,
  isSpouseExecutor = "",
  isQuebecSelected = false,
  canAddMore = true,
  hasNoSelectedContacts,
  spouseContactId = null,
}) => {
  const [isWaitingSelection, setIsWaitingSelection] = useState(false);
  const [displayCoFieldDropdown, setDisplayCoFieldDropdown] = useState({});
  const dispatch = useDispatch();
  const contacts = useSelector(selectAllContacts);
  const hasUserPaid = useSelector(selectHasUserPaid);

  useEffect(() => {
    // when a new contact is added this will remove the waiting state
    setIsWaitingSelection(false);
  }, [contacts]);

  const allFields = fields.getAll() || [];
  const allSelectedContacts = allFields.reduce((arr, field) => {
    if (field.secondaryContact?.id) {
      return [...arr, field.contact?.id, field.secondaryContact?.id];
    }
    if (field.contact?.id) {
      return [...arr, field.contact?.id];
    }
    return arr;
  }, []);
  const validFields = allFields.filter((field) => field._destroy !== true);
  const hasValidFields = validFields.length > 0;

  const hasUserDecidedIfSpouseIsExecutor =
    !hasSpouse || isSpouseExecutor !== "";
  const hasNoExecutorsAndSpouseIsNotExecutor =
    hasNoSelectedContacts && !isSpouseExecutor;
  const forceHidingAddMoreButton =
    (!hasSpouse && hasNoSelectedContacts) ||
    (hasValidFields && hasNoSelectedContacts) ||
    hasNoExecutorsAndSpouseIsNotExecutor;
  const forceHidingRemoveButton =
    hasNoSelectedContacts && hasValidFields && !isSpouseExecutor;

  const shouldShowAddMoreButton =
    !forceHidingAddMoreButton &&
    canAddMore &&
    hasUserDecidedIfSpouseIsExecutor &&
    !isWaitingSelection;

  const hasAppointeeTitle = R.has("total")(appointeeLevelIndexs);
  const addButtonLabel = hasValidFields
    ? translations.button.addMoreLabel
    : translations.button.addLabel;

  // if user has no spouse and there are no fields yet, adds an empty new field
  if (!hasSpouse && fields.length === 0) {
    fields.push({});
  }

  if (hasSpouse && hasUserDecidedIfSpouseIsExecutor) {
    // if the user has a spouse but the spouse is not selected as executor,
    // adds an empty new field and hides the add more button
    if (!hasValidFields && hasNoExecutorsAndSpouseIsNotExecutor) {
      fields.push({});
    }
  }

  const handleAddMoreFields = () => {
    setIsWaitingSelection(true);
    fields.push({});
  };

  let firstFieldFlag = true;

  return (
    <Box>
      {subQuestionText && (
        <Box mb={2}>
          <Body
            dangerouslySetInnerHTML={{
              __html: subQuestionText,
            }}
          />
        </Box>
      )}

      {fields.map((fieldId, fieldIndex) => {
        const isFirstField = firstFieldFlag;
        firstFieldFlag = false;

        const field = fields.get(fieldIndex);
        if (field && field._destroy === true) {
          return null;
        }
        const fieldExecutorId = field?.id;
        const fieldContactId = field?.contact?.id;
        const fieldSecondaryContactId = field?.secondaryContact?.id;
        const filteredContacts = contacts.filter((person) => {
          const contactId = person.contact.id;
          const keepSelectedContact = fieldContactId
            ? contactId === fieldContactId ||
              contactId === fieldSecondaryContactId
            : false;
          return (
            keepSelectedContact || !allSelectedContacts.includes(contactId)
          );
        });

        const executorLevelText = getExecutorLevelTranslation(
          appointeeLevelIndexs,
          fieldIndex,
          translations,
          isSpouseExecutor,
        );
        const fieldLabel = hasAppointeeTitle ? executorLevelText : "";

        const coExecutorLevelText = getExecutorLevelTranslation(
          appointeeLevelIndexs,
          fieldIndex,
          translations,
          isSpouseExecutor,
          true,
        );
        const secondaryFieldLabel = hasAppointeeTitle
          ? coExecutorLevelText
          : "";

        const shouldShowCoField =
          !isQuebecSelected &&
          fieldContactId &&
          (displayCoFieldDropdown[fieldIndex] || fieldSecondaryContactId);
        const shouldShowSubQuestionOptional =
          isFirstField && !hasNoSelectedContacts && subQuestionOptionalText;
        const shouldShowNotifyButton = hasUserPaid && fieldContactId;
        const shouldShowNotifyCoButton = hasUserPaid && fieldSecondaryContactId;

        const handleAddCoField = (dropdownIndex) => {
          setDisplayCoFieldDropdown((prevState) => ({
            ...prevState,
            [dropdownIndex]: true,
          }));
        };

        const handleSelectContact = (contactId, isSecondaryContact = false) => {
          dispatch(
            selectContactFromDropdown(
              contactId,
              fieldExecutorId,
              EXECUTOR_CONTACTABLE_MODEL,
              isSecondaryContact,
            ),
          );
          setIsWaitingSelection(false);
        };

        const handleAddNewContact = (isSecondaryContact) => {
          dispatch(
            openModalContactForContactable(
              null,
              MODAL_ADD_CONTACT_EXECUTOR,
              fieldExecutorId,
              isSecondaryContact,
            ),
          );
        };

        const handleRemoveField = () => {
          if (field.id && fieldContactId) {
            dispatch(removeExecutors(field));
          } else {
            handleChange(`${fieldId}._destroy`, true);
          }
          return setIsWaitingSelection(false);
        };

        const handleRemoveCoField = (dropdownIndex) => {
          if (fieldSecondaryContactId) {
            dispatch(
              removeSecondaryContact(
                fieldContactId,
                fieldExecutorId,
                EXECUTOR_CONTACTABLE_MODEL,
              ),
            );
          }
          setDisplayCoFieldDropdown((prevState) => ({
            ...prevState,
            [dropdownIndex]: false,
          }));
        };

        return (
          <Box mb={1} key={`${fieldIndex + 1}-${fieldId}`}>
            <DropdownContactSelect
              id={fieldId}
              fieldId={fieldId}
              label={fieldLabel}
              contacts={filteredContacts.filter(
                (c) => c.contact.id !== fieldSecondaryContactId,
              )}
              disabledContactIds={[spouseContactId]}
              selectedContactId={fieldContactId}
              onSelect={handleSelectContact}
              onAddNew={handleAddNewContact}
            />
            <Box
              mt={1}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {!isQuebecSelected && fieldContactId && !shouldShowCoField && (
                <AddCoFieldButton
                  id={`co-${fieldId}`}
                  text={translations.addCoExecutor}
                  onClick={() => handleAddCoField(fieldIndex)}
                  hasMargin={
                    isSpouseExecutor || !isFirstField || shouldShowNotifyButton
                  }
                  index={fieldIndex}
                />
              )}
              {shouldShowNotifyButton && (
                <NotifyContactButton
                  hasMargin={isSpouseExecutor || !isFirstField}
                  className={`qa-notify-contact-${fieldIndex}`}
                />
              )}
              {!forceHidingRemoveButton &&
                (isSpouseExecutor || !isFirstField) && (
                  <SmallTextButton
                    onClick={handleRemoveField}
                    text={translations.remove}
                    className={`qa-remove-people-${fieldIndex}`}
                  />
                )}
            </Box>
            {shouldShowCoField && (
              <Box>
                <DropdownContactSelect
                  id={`co-${fieldId}`}
                  fieldId={`co-${fieldId}`}
                  label={secondaryFieldLabel}
                  contacts={filteredContacts.filter(
                    (c) => c.contact.id !== fieldContactId,
                  )}
                  disabledContactIds={[spouseContactId]}
                  selectedContactId={fieldSecondaryContactId}
                  onSelect={(contactId) => handleSelectContact(contactId, true)}
                  onAddNew={() => handleAddNewContact(true)}
                />
                <Box
                  mt={1}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {shouldShowNotifyCoButton && (
                    <NotifyContactButton
                      hasMargin
                      className={`qa-notify-co-contact-${fieldIndex}`}
                    />
                  )}
                  <SmallTextButton
                    onClick={() => handleRemoveCoField(fieldIndex)}
                    text={`${translations.remove} ${translations.coExecutorTitle}`}
                    className={`qa-remove-co-field-${fieldIndex}`}
                  />
                </Box>
              </Box>
            )}
            {shouldShowSubQuestionOptional && (
              <Box my={2}>
                <Body
                  dangerouslySetInnerHTML={{
                    __html: subQuestionOptionalText,
                  }}
                />
              </Box>
            )}
          </Box>
        );
      })}
      {shouldShowAddMoreButton && (
        <Box mt={2} mb={isTabletDown && 2}>
          <SecondaryButton
            text={addButtonLabel}
            fullWidth
            displayPlusIcon
            className="qa-add"
            type="button"
            onClick={handleAddMoreFields}
          />
        </Box>
      )}
    </Box>
  );
};

const ExecutorsForm = ({
  error,
  handleSubmit,
  change,
  backLink,
  hasSpouse,
  appointeeLevelIndexs,
  isSpouseExecutor,
  isLoading,
  translations,
  tooltipKey,
  shouldShowCTAButton,
  isQuebecSelected,
}) => {
  const {
    provinceSpecificInfo,
    provinceSpecificLink,
    userSpouseExecutorQuestion,
    subQuestion,
    subQuestionOptional,
    addInfo,
  } = translations;
  const useValidateSpouseIsExecutor = useValidateSpouseIsExecutorMemo(
    translations,
  );
  let subQuestionText = "";
  if (hasSpouse && shouldShowCTAButton) {
    subQuestionText = isSpouseExecutor ? subQuestionOptional : subQuestion;
  }
  let subQuestionOptionalText = "";
  if (!hasSpouse || subQuestionText === subQuestion) {
    subQuestionOptionalText = subQuestionOptional;
  }

  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const savedExecutors = useSelector(selectExecutors);
  const hasNoSelectedContacts =
    savedExecutors.filter((e) => e.contact !== null).length === 0;
  const hasNoValidExecutor = !isSpouseExecutor && hasNoSelectedContacts;

  const spouseContact = R.pathOr({}, "")(useSelector(selectSpouseContact))[0];
  const spouseContactId = isSpouseExecutor ? spouseContact?.contact?.id : null;
  let duplicatedSpouseError = "";
  if (isSpouseExecutor) {
    const contactIds = savedExecutors.map((field) => field.contact?.id);
    const secondaryContactIds = savedExecutors.map(
      (field) => field.secondaryContact?.id,
    );
    // if user said that spouse is primary executor but also selected spouse as executor,
    // we will show an error message to guide the user to change their selection
    if ([...contactIds, ...secondaryContactIds].includes(spouseContactId)) {
      duplicatedSpouseError = translations.duplicatedSpouseError;
    }
  }
  const shouldDislayError = error || duplicatedSpouseError;

  return (
    <Form
      onSubmit={handleSubmit}
      backLink={backLink}
      translations={translations}
      isLoading={isLoading}
      HeaderComponent={provinceSpecificInfo && ProvincialWarning}
      text={provinceSpecificInfo}
      link={provinceSpecificLink}
      addInfo={addInfo}
      tooltipKey={tooltipKey}
      reverseWrap={isTabletDown}
      disabled={hasNoValidExecutor || shouldDislayError}
    >
      {hasSpouse && (
        <>
          <Body data-hj-suppress>{userSpouseExecutorQuestion}</Body>
          <BinaryRadioField
            name={SPOUSE_IS_EXECUTOR_FIELD_ID}
            translations={translations}
            validate={useValidateSpouseIsExecutor}
            optionList={translations.optionList}
          />
        </>
      )}
      <FieldArray
        rerenderOnEveryChange
        handleChange={change}
        removeExecutors={removeExecutors}
        name={EXECUTORS_FIELD_ID}
        component={ExecutorsDropdownFieldArray}
        hasSpouse={hasSpouse}
        isSpouseExecutor={isSpouseExecutor}
        appointeeLevelIndexs={appointeeLevelIndexs}
        translations={translations}
        canAddMore={shouldShowCTAButton}
        isQuebecSelected={isQuebecSelected}
        subQuestionText={subQuestionText}
        subQuestionOptionalText={subQuestionOptionalText}
        isTabletDown={isTabletDown}
        hasNoSelectedContacts={hasNoSelectedContacts}
        spouseContactId={spouseContactId}
      />
      <Box mb={shouldDislayError && 1}>
        <ErrorMessage error={error || duplicatedSpouseError} />
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: EXECUTORS_FORM_ID,
})(ExecutorsForm);

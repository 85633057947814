import { takeEvery, call, put } from "redux-saga/effects";
import {
  getCheckout,
  postAddonEndpoint,
  removeAddonEndpoint,
} from "../api/checkout";
import { fetchApiData } from "./requests";
import {
  FETCH_CART_TYPE,
  updateCart,
  ADD_ADDON_TYPE,
  REMOVE_ADDON_TYPE,
  toggleCartWidget,
} from "../actions/cart";
import { submitFormRequest } from "./forms";
import {
  analyticsAddedAddonToCart,
  analyticsRemovedAddonFromCart,
} from "../actions/analytics";

function* fetchCart() {
  const { order } = yield call(fetchApiData, {
    apiCall: getCheckout,
    formId: "cart",
  });

  yield put(updateCart(order));
}

export function* addAddon(action) {
  const { addons } = action.payload;

  const { order } = yield call(submitFormRequest, {
    apiCall: postAddonEndpoint,
    formId: "addon",
    values: { addons },
  });

  yield put(updateCart(order));
  yield put(
    analyticsAddedAddonToCart({
      name: "Digital Execution",
      quantity: 1,
    }),
  );
  yield put(toggleCartWidget(true));
}

export function* removeAddon(action) {
  const { addon_id } = action.payload;
  const { order } = yield call(submitFormRequest, {
    apiCall: removeAddonEndpoint,
    formId: "addon",
    values: { addon_id },
  });
  yield put(updateCart(order));
  yield put(
    analyticsRemovedAddonFromCart({
      name: "Digital Execution",
      quantity: 1,
    }),
  );
}

export function* watchFetchCart() {
  yield takeEvery(FETCH_CART_TYPE, fetchCart);
}

export function* watchFetchCartAddon() {
  yield takeEvery(ADD_ADDON_TYPE, addAddon);
}

export function* watchRemoveCartAddon() {
  yield takeEvery(REMOVE_ADDON_TYPE, removeAddon);
}

import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import { Collapse } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Body, LargeBody, SmallBody } from "../../ui/Typography";
import { InfoBox } from "../../ui/InfoBox";
import BirthdayIcon from "../../ui/icons/BirthdayIcon";
import StatusLabel from "../../ui/StatusLabel";

import { animationSpeed } from "../../../config/Theme";
import PromoCodeCollapsibleForm from "../../forms/checkout/PromoCodeCollapsibleForm";
import CaaMembershipForm from "../../forms/checkout/CaaMembershipForm";
import { selectAddonsFormatted, isAddonInCart } from "../../../selectors/cart";
import {
  selectAddonsReceiptFormatted,
  isAddonReceipt,
} from "../../../selectors/receipt";
import PaymentSummaryProductInfoContent from "./ProductInfoContent";

// TODO: Refactor this file to separate files and add correct interfaces under types folder

const PlanTotal = ({ translations, total, isReceiptPage }) => {
  const totalPaid = translations.totalPaidToday;
  const totalAmount = translations.totalForToday;
  return (
    <>
      <Box
        my={1}
        border={1}
        borderColor="border.main"
        borderLeft={0}
        borderRight={0}
        borderTop={0}
      />
      <Box tabIndex={0} mt={1} display="flex" justifyContent="space-between">
        <Box>
          <Body component="span">
            {isReceiptPage ? totalPaid : totalAmount}
          </Body>
        </Box>
        <Box>
          <Body component="span" bold={false}>{`${translations.CAD} `}</Body>
          <Body component="span">{total}</Body>
        </Box>
      </Box>
    </>
  );
};

const CollapsibleSummary = ({
  translations,
  orderValue,
  tax,
  taxType,
  total,
  hasDiscount,
  hasCaaMembership,
  handleRemoveReferral,
  discountsData,
  isReceiptPage,
  promoCode,
  isLoading,
  plansDetails = [],
  shouldDisplayPromoCodeField,
  displayTooltipModal,
  handlePromoCodeSubmit,
  promoForm,
  shouldDisplayCaaField,
  handleCaaFormSubmit,
  caaForm,
  addonsInCart = [],
  isAddon,
}) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleCollapse = (e, isFocusing = false) => {
    if (isFocusing && !collapsed) {
      // don't collapse if user is focusing on the
      // collapsible and it is already expanded
      return;
    }
    setCollapsed(!collapsed);
  };

  const collapsibleLabel = collapsed
    ? translations.expand
    : translations.collapse;

  return (
    <>
      <Box
        tabIndex={0}
        onMouseDown={(e) => e.preventDefault()} // prevent triggering focus on click
        onClick={(e) => handleCollapse(e)}
        onFocus={(e) => handleCollapse(e, true)}
      >
        <label htmlFor={translations.totals} aria-label={translations.totals}>
          <Box
            id={translations.totals}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            aria-expanded={!collapsed}
          >
            <Box>
              <LargeBody component="span" margin="0">
                <strong>{translations.totals}</strong>
              </LargeBody>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              pt={0.5}
              style={{ cursor: "pointer" }}
            >
              <SmallBody bold color="willfulBlue">
                {collapsibleLabel}
              </SmallBody>
            </Box>
          </Box>
        </label>
      </Box>

      <Collapse in={!collapsed} timeout={animationSpeed.fast}>
        <PaymentSummaryProductInfoContent
          translations={translations}
          orderValue={orderValue}
          plansDetails={plansDetails}
          addonsInCart={addonsInCart}
          hasDiscount={hasDiscount}
          discountsData={discountsData}
          isReceiptPage={isReceiptPage}
          isLoading={isLoading}
          handleRemoveReferral={handleRemoveReferral}
          promoCode={promoCode}
          hasCaaMembership={hasCaaMembership}
          isAddon={isAddon}
        />

        <Box tabIndex={0} display="flex" justifyContent="space-between">
          <Box py={0.25}>
            <Body component="span" bold={false}>
              {translations.tax} ({taxType})
            </Body>
          </Box>
          <Box py={0.25}>
            <Body component="span" bold={false}>
              {tax}
            </Body>
          </Box>
        </Box>

        <PlanTotal
          translations={translations}
          total={total}
          isReceiptPage={isReceiptPage}
        />

        {/* Promo code field */}
        {shouldDisplayPromoCodeField && addonsInCart.length === 0 && (
          <PromoCodeCollapsibleForm
            onSubmit={handlePromoCodeSubmit}
            displayTooltipModal={displayTooltipModal}
            {...promoForm}
          />
        )}
        {/* CAA Field */}
        {shouldDisplayCaaField && (
          <CaaMembershipForm
            onSubmit={handleCaaFormSubmit}
            displayTooltipModal={displayTooltipModal}
            {...caaForm}
          />
        )}
      </Collapse>

      {/* When summary is collapsed show only the plan total */}
      {collapsed && (
        <PlanTotal
          translations={translations}
          total={total}
          isReceiptPage={isReceiptPage}
        />
      )}
    </>
  );
};
// Default view for receipt page
const StaticSummary = ({
  translations,
  orderValue,
  tax,
  taxType,
  total,
  hasDiscount,
  hasCaaMembership,
  handleRemoveReferral,
  discountsData,
  isReceiptPage,
  promoCode,
  isLoading,
  planSummary,
  plansDetails = [],
  shouldDisplayPromoCodeField,
  displayTooltipModal,
  handlePromoCodeSubmit,
  promoForm,
  shouldDisplayCaaField,
  handleCaaFormSubmit,
  caaForm,
  addonsInCart = [],
  isAddon,
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <LargeBody component="span" margin="0">
            <strong>{translations.totals}</strong>
          </LargeBody>
        </Box>
        {isReceiptPage && (
          <Box alignItems="center" display="flex">
            <StatusLabel
              status={planSummary.hasUserPaid ? "paid" : "unpaid"}
              translations={translations}
            />
          </Box>
        )}
      </Box>
      <PaymentSummaryProductInfoContent
        translations={translations}
        orderValue={orderValue}
        plansDetails={plansDetails}
        addonsInCart={addonsInCart}
        hasDiscount={hasDiscount}
        discountsData={discountsData}
        isReceiptPage={isReceiptPage}
        isLoading={isLoading}
        handleRemoveReferral={handleRemoveReferral}
        promoCode={promoCode}
        hasCaaMembership={hasCaaMembership}
        isAddon={isAddon}
      />
      <Box display="flex" justifyContent="space-between">
        <Box py={0.25}>
          <Body component="span" bold={false}>
            {translations.tax} ({taxType})
          </Body>
        </Box>
        <Box py={0.25}>
          <Body component="span" bold={false}>
            {tax}
          </Body>
        </Box>
      </Box>
      <PlanTotal
        translations={translations}
        total={total}
        isReceiptPage={isReceiptPage}
      />

      {/* Promo code field */}
      {shouldDisplayPromoCodeField && addonsInCart.length === 0 && (
        <PromoCodeCollapsibleForm
          onSubmit={handlePromoCodeSubmit}
          displayTooltipModal={displayTooltipModal}
          {...promoForm}
        />
      )}
      {/* CAA Field */}
      {shouldDisplayCaaField && (
        <CaaMembershipForm
          onSubmit={handleCaaFormSubmit}
          displayTooltipModal={displayTooltipModal}
          {...caaForm}
        />
      )}
    </>
  );
};

const PaymentSummary = ({
  isDesktop,
  hasReferral,
  isReceiptPage,
  translations,
  ...checkoutInfo
}) => {
  const showCollapsibleSummary = !isDesktop && !isReceiptPage;
  const summaryProps = {
    translations,
    isReceiptPage,
    ...checkoutInfo,
  };
  const addonsInCart = useSelector(
    isReceiptPage ? selectAddonsReceiptFormatted : selectAddonsFormatted,
  );
  const isAddon = useSelector(isReceiptPage ? isAddonReceipt : isAddonInCart);

  return (
    <Box>
      {hasReferral && (
        <Box mb={2}>
          <InfoBox
            title={translations.referralInfoBoxTitle}
            paragraph={translations.referralInfoBoxText}
            icon={<BirthdayIcon />}
          />
        </Box>
      )}
      {showCollapsibleSummary ? (
        <CollapsibleSummary
          {...summaryProps}
          addonsInCart={addonsInCart}
          isAddon={isAddon}
        />
      ) : (
        <StaticSummary
          {...summaryProps}
          addonsInCart={addonsInCart}
          isAddon={isAddon}
        />
      )}
    </Box>
  );
};

export default PaymentSummary;

import { combineReducers } from "redux";
import {
  UPDATE_CART_TYPE,
  CLEAR_CART_TYPE,
  TOGGLE_CART_WIDGET,
} from "../actions/cart";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const itemCount = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.cartItemCount;
    default:
      return state;
  }
};

const addonCount = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.cartAddonCount;
    default:
      return state;
  }
};

const items = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.items;
    case CLEAR_CART_TYPE:
      return [];
    default:
      return state;
  }
};

const addons = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.addons;
    case CLEAR_CART_TYPE:
      return [];
    default:
      return state;
  }
};

const subtotalBeforeDiscounts = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.subtotalBeforeDiscounts;
    default:
      return state;
  }
};
const subtotal = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.subtotal;
    default:
      return state;
  }
};

const tax = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.tax;
    default:
      return state;
  }
};
const total = (state = 0, { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.total;
    default:
      return state;
  }
};
const province = (state = {}, { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.province;
    default:
      return state;
  }
};

const discounts = (state = [], { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.discounts;
    default:
      return state;
  }
};

const showCaaField = (state = false, { type, payload }) => {
  switch (type) {
    case UPDATE_CART_TYPE:
      return payload.showCaaField;
    default:
      return state;
  }
};

const isCartWidgetOpen = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CART_WIDGET:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  itemCount,
  addonCount,
  items,
  addons,
  subtotal,
  subtotalBeforeDiscounts,
  tax,
  total,
  province,
  discounts,
  showCaaField,
  isCartWidgetOpen,
});
